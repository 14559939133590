import { defineComponent, PropType } from "vue";
import { IMenuItem } from ".";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
    value: [String, Number],
  },
});
