
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton/";
import dayjs from "dayjs";

export default defineComponent({
  name: "MdDatepicker",
  components: {
    MdButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<Date>,
    },
  },

  emits: ["update:modelValue", "save"],
  setup() {
    return {
      weekdays: ["M", "D", "M", "D", "F", "S", "S"],
      weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      monthNamesShort: [
        "Jan.",
        "Feb.",
        "Mär.",
        "Apr.",
        "Mai",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Okt.",
        "Nov.",
        "Dez.",
      ],
    };
  },
  data() {
    return {
      visible: false,
      startDate: new Date(),
      endDate: new Date(),
      currentYear: 2022,
      currentMonth: 0,
      previewDates: [{ day: 0, month: 0, year: 0 }],
      lockRange: true,
      parsedDates: { parsedStartDate: "", parsedEndDate: "" },
      pickingMonth: false,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value: { startDate: Date; endDate: Date } | null) {
        if (!value) return;
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.currentYear = this.startDate.getFullYear();
        this.currentMonth = this.startDate.getMonth();
        let tempDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth(),
          this.startDate.getDate() + 1
        );
        this.parsedDates.parsedEndDate = dayjs(this.endDate).format(
          "DD.MM.YYYY"
        );
        this.parsedDates.parsedStartDate = dayjs(
          new Date(this.startDate)
        ).format("DD.MM.YYYY");
        while (
          tempDate <
          new Date(
            this.endDate.getFullYear(),
            this.endDate.getMonth(),
            this.endDate.getDate()
          )
        ) {
          this.previewDates.push({
            day: tempDate.getDate(),
            month: tempDate.getMonth() + 1,
            year: tempDate.getFullYear(),
          });
          tempDate = new Date(
            tempDate.getFullYear(),
            tempDate.getMonth(),
            tempDate.getDate() + 1
          );
        }
      },
    },
  },
  methods: {
    existsInArray(incDate: { day: number; month: number; year: number }) {
      for (let i = 0; i < this.previewDates.length; i++) {
        if (
          incDate.day === this.previewDates[i].day &&
          incDate.month === this.previewDates[i].month &&
          incDate.year === this.previewDates[i].year
        ) {
          return true;
        }
      }
    },
    pickMonth(month: string) {
      this.currentMonth = this.monthNames.indexOf(month);
      this.pickingMonth = false;
    },
    goToMonths() {
      this.pickingMonth = !this.pickingMonth;
    },
    test(day: number, currentMonth: number, currentYear: number) {
      if (!this.lockRange) {
        this.previewDates.splice(0);
        let tempDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth(),
          this.startDate.getDate() + 1
        );
        if (tempDate < new Date(currentYear, currentMonth, day)) {
          while (tempDate < new Date(currentYear, currentMonth, day)) {
            this.previewDates.push({
              day: tempDate.getDate(),
              month: tempDate.getMonth() + 1,
              year: tempDate.getFullYear(),
            });
            tempDate = new Date(
              tempDate.getFullYear(),
              tempDate.getMonth(),
              tempDate.getDate() + 1
            );
          }
        } else {
          let tempDate = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth(),
            this.startDate.getDate() - 1
          );
          while (tempDate > new Date(currentYear, currentMonth, day)) {
            this.previewDates.push({
              day: tempDate.getDate(),
              month: tempDate.getMonth() + 1,
              year: tempDate.getFullYear(),
            });
            tempDate = new Date(
              tempDate.getFullYear(),
              tempDate.getMonth(),
              tempDate.getDate() - 1
            );
          }
        }
      }
    },
    open() {
      this.visible = true;
      document.documentElement.style.overflow = "hidden";
    },
    close() {
      this.visible = false;
      document.documentElement.style.overflow = "auto";
    },
    selectDay(day: number) {
      if (this.lockRange) {
        this.lockRange = !this.lockRange;
        const date = new Date(this.startDate);
        date.setFullYear(this.currentYear, this.currentMonth, day);
        this.startDate = date;
        this.endDate = date;
        this.previewDates.splice(0);
        this.parsedDates.parsedStartDate = dayjs(
          new Date(this.startDate)
        ).format("DD.MM.YYYY");
      } else {
        this.endDate = new Date(this.currentYear, this.currentMonth, day);
        if (this.endDate < this.startDate) {
          const tmp = this.endDate;
          this.endDate = this.startDate;
          this.startDate = tmp;
        }
        this.lockRange = true;
        this.parsedDates.parsedStartDate = dayjs(this.startDate).format(
          "DD.MM.YYYY"
        );
        this.parsedDates.parsedEndDate = dayjs(this.endDate).format(
          "DD.MM.YYYY"
        );
      }
    },
    select() {
      this.$emit("update:modelValue", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.$emit("save");
      this.close();
    },
    getDayIndex(day: number): number {
      let index = new Date(this.currentYear, this.currentMonth, day).getDay();
      if (index == 0) {
        index = 6;
      } else {
        index--;
      }
      return index;
    },
    nextMonth() {
      this.currentMonth++;
      if (this.currentMonth >= 12) {
        this.currentMonth = 0;
        this.currentYear++;
      }
    },
    prevMonth() {
      this.currentMonth--;
      if (this.currentMonth <= 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
    },
  },
  computed: {
    days(): number {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    },
    today(): Date {
      return new Date();
    },
  },
});
