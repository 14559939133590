
import { computed, defineComponent } from "vue";
import MdInputMixin from "./MdInputMixin";
import { uuid } from "@/utils/MdUuid";

export default defineComponent({
  name: "MdInputField",
  mixins: [MdInputMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("input-field"),
    },
  },
  setup(props) {
    const formattedModelValue = computed(() => {
      if (!props.formatNumber) {
        return props.modelValue;
      } else if (props.modelValue) {
        // if formatNumber is true we want to turn somthing like 1000000 -> 1.000.000
        const parts = props.modelValue.toString().split(".");
        parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        return parts.join(",");
      } else {
        return "";
      }
    });
    return {
      formattedModelValue,
    };
  },
});
