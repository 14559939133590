import { defineComponent, PropType } from "vue";
import { Validator } from "@/classes/validators/Validator";

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<"outlined" | "flat">,
      default: "outlined",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    autoComplete: {
      type: String,
      default: "on",
    },
    required: Boolean,
    disabled: Boolean,
    icon: String,
    trailingIcon: String,
    placeholder: {
      type: String,
      default: "",
    },
    label: String,
    validator: Object as PropType<Validator>,
    error: {
      type: [String, Boolean],
      default: "",
    },
    size: String as PropType<"normal" | "small">,
    maxCharacters: Number,
    align: String as PropType<"left" | "center" | "right">,
    formatNumber: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:modelValue",
    "update:text",
    "focus",
    "blur",
    "change",
    "keydown",
    "keypress",
    "keyup",
    "icon-click",
    "trailing-icon-click",
  ],
  computed: {
    placeholderValue(): string {
      return this.focused ? this.placeholder : "";
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        this.hasValue = newValue.toString().length > 0;
      },
    },
  },
  data() {
    return {
      hasValue: false,
      focused: false,
      errorMessage: "",
    };
  },
  methods: {
    focusIn() {
      (this.$refs["input"] as HTMLInputElement).focus();
      const end = this.modelValue.toString().length;
      if (this.type !== "number" && this.type !== "date")
        (this.$refs["input"] as HTMLInputElement).setSelectionRange(end, end);
    },
    onFocus(event: FocusEvent) {
      this.focused = true;
      this.$emit("focus", event);
    },
    onIconClick() {
      this.focusIn();
      this.$emit("icon-click");
    },
    onTrailingIconClick() {
      this.focusIn();
      this.$emit("trailing-icon-click");
    },
    onBlur() {
      this.focused = false;
      this.$emit("blur");
      this.validate();
    },
    onChange(event: Event) {
      let value = (event.target as HTMLInputElement).value;
      this.hasValue = value.toString().length > 0;
      if (this.formatNumber) {
        value = parseFloat(
          value.toString().replaceAll(".", "").replaceAll(",", ".")
        ).toString();
      }
      this.$emit("update:modelValue", value);
      this.$emit("update:text", value);
      this.$emit("change", event);
      this.validate();
    },
    onKeydown(event: KeyboardEvent) {
      if (
        this.maxCharacters &&
        (event.target as HTMLInputElement).value.length >= this.maxCharacters
      )
        return event.preventDefault();
      this.$emit("keydown", event);
    },
    validate() {
      if (this.validator) {
        this.validator.setValue(this.modelValue);
        if (this.validator.isValid()) {
          this.errorMessage = "";
        } else {
          this.errorMessage = this.validator.getErrorMessage();
        }
      }
    },
    setErrorMessage(message: string) {
      this.errorMessage = message;
    },
  },
});
