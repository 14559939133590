import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60c9ee2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kpi-content" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "headline" }
const _hoisted_4 = { class: "subline" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "subline" }
const _hoisted_7 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_card = _resolveComponent("md-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_card, {
      class: "kpi-card",
      variant: "elevated"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "headline", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "subline", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "value", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "subline-two", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "value-two", {}, undefined, true)
          ])
        ])
      ]),
      _: 3
    })
  ]))
}