import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-152342e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["x", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_loader = _resolveComponent("content-loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md-table-cell-loader", { numeric: _ctx.numeric }])
  }, [
    _createVNode(_component_content_loader, {
      viewBox: "0 0 300 14",
      speed: 1.2,
      primaryColor: "#C2CCD1",
      secondaryColor: "#D6DEE3"
    }, {
      default: _withCtx(() => [
        _createElementVNode("rect", {
          x: _ctx.posX,
          y: "0",
          rx: "4",
          ry: "4",
          width: _ctx.width,
          height: "14"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["speed"])
  ], 2))
}