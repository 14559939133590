import { defineStore } from "pinia";

interface State {
  primaryColor: string;
  onPrimaryColor: string;
  textColor: string;
  backgroundColorScreen: string;
  backgroundColorComponent: string;
  backgroundColorTooltip: string;
  surfaceColor: string;
  chartHeadlineColor: string;
  chartSublineColor: string;
  chartTextColor: string;
  chartScaleColor: string;
  chartBackgroundColor: string;
}

export const useStore = defineStore("colorStore", {
  state: (): State => {
    return {
      primaryColor: getComputedStyle(document.body).getPropertyValue(
        "--primary"
      ),
      onPrimaryColor: getComputedStyle(document.body).getPropertyValue(
        "--on-primary"
      ),
      textColor: getComputedStyle(document.body).getPropertyValue(
        "--text-color"
      ),
      backgroundColorScreen: getComputedStyle(document.body).getPropertyValue(
        "--background-color-screen"
      ),
      backgroundColorComponent: getComputedStyle(
        document.body
      ).getPropertyValue("--background-color-component"),
      backgroundColorTooltip: getComputedStyle(document.body).getPropertyValue(
        "--background-color-tooltip"
      ),
      surfaceColor: getComputedStyle(document.body).getPropertyValue(
        "--surface"
      ),
      chartHeadlineColor: getComputedStyle(document.body).getPropertyValue(
        "--chart-headline-color"
      ),
      chartSublineColor: getComputedStyle(document.body).getPropertyValue(
        "--chart-subline-color"
      ),
      chartTextColor: getComputedStyle(document.body).getPropertyValue(
        "--chart-text-color"
      ),
      chartScaleColor: getComputedStyle(document.body).getPropertyValue(
        "--chart-scale-color"
      ),
      chartBackgroundColor: getComputedStyle(document.body).getPropertyValue(
        "--chart-background-color"
      ),
    };
  },
});
