import {
  Chart,
  ChartOptions,
  ChartType,
  Tooltip,
  TooltipItem,
  TooltipPositionerFunction,
} from "chart.js";
import {
  numberToCurrencyValue,
  numberToPercentageValue,
} from "@/utils/NumberFormatUtil";
import { useStore as useColorStore } from "@/store/ColorStore";

declare module "chart.js" {
  interface TooltipPositionerMap {
    myCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

export const defaultChartConfig = () => {
  Chart.defaults.responsive = true;
  Chart.defaults.maintainAspectRatio = false;
  Chart.defaults.layout.padding = 0;
  Chart.defaults.plugins.legend.labels.usePointStyle = true;
  Chart.defaults.plugins.legend.labels.boxWidth = 10;
  Chart.defaults.plugins.legend.labels.boxHeight = 10;
  Chart.defaults.plugins.legend.labels.padding = 15;
  Chart.defaults.plugins.legend.labels.font = {
    family: "Roboto",
    style: "normal",
    weight: "500",
    size: 14,
  };
  Tooltip.positioners.myCustomPositioner = (items) => {
    const pos = items[0]?.element;
    if (pos) {
      return {
        x: pos.x,
        y: pos.y - 54,
        xAlign: "center",
        yAlign: "top",
      };
    } else return { x: 0, y: 0 };
  };
  Chart.defaults.plugins.legend.position = "bottom";
  Chart.defaults.plugins.legend.align = "start";
  Chart.defaults.plugins.tooltip.enabled = true;
  Chart.defaults.plugins.tooltip.displayColors = false;
  Chart.defaults.plugins.tooltip.position = "myCustomPositioner";
  Chart.defaults.plugins.tooltip.caretSize = 0;
  Chart.defaults.plugins.tooltip.caretPadding = 0;
  Chart.defaults.plugins.tooltip.backgroundColor =
    useColorStore().backgroundColorTooltip;
  Chart.defaults.plugins.tooltip.padding = {
    left: 15,
    right: 15,
    top: 5,
    bottom: 5,
    width: 10,
    height: 10,
  };
  // cornerRadius is just guessed
  Chart.defaults.plugins.tooltip.cornerRadius = 27;
  Chart.defaults.plugins.tooltip.titleFont = {
    family: "Roboto",
    style: "normal",
    weight: "700",
    size: 12,
  };
  Chart.defaults.plugins.tooltip.bodyFont = {
    family: "Roboto",
    style: "normal",
    weight: "500",
    size: 12,
  };
  Chart.defaults.plugins.tooltip.footerFont = {
    family: "Roboto",
    style: "normal",
    weight: "700",
    size: 12,
  };
  Chart.defaults.plugins.tooltip.titleAlign = "center";
  Chart.defaults.plugins.tooltip.bodyAlign = "center";
  Chart.defaults.plugins.tooltip.footerAlign = "center";
};

export const defaultBarChartConfig = (): ChartOptions => {
  return {
    resizeDelay: 100,
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: 0 },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          title: (cData: TooltipItem<ChartType>[]) => {
            return `${cData[0].dataset.label}`;
          },
          label: (cData: TooltipItem<ChartType>) => {
            return `${numberToCurrencyValue(cData.raw as number)}`;
          },
        },
      },
    },
    elements: { bar: { borderRadius: 2 } },
    scales: {
      x: {
        grid: { display: false, drawBorder: false },
        ticks: {
          font: {
            family: "Roboto",
            style: "normal",
            size: 14,
            weight: "400",
          },
          maxRotation: 0,

          color: useColorStore().chartHeadlineColor,
        },
      },
      y: {
        position: "right",
        grid: { display: true, drawBorder: false },
        ticks: {
          font: {
            family: "Roboto",
            style: "normal",
            size: 14,
            weight: "400",
          },

          color: useColorStore().chartScaleColor,
          callback: (value: string | number) =>
            Intl.NumberFormat("de", { notation: "standard" }).format(
              parseInt(value as string)
            ),
        },
      },
    },
  };
};
export const defaultDoughnutChartConfig = (): ChartOptions => {
  return {
    responsive: true,
    radius: 115,
    cutout: "75%",
    elements: { arc: { borderWidth: 0 } },
    plugins: {
      legend: { maxWidth: 200, fullSize: false, labels: {}, itemWidth: 50 },
      tooltip: {
        callbacks: {
          title: (cData: TooltipItem<ChartType>[]) => {
            return `${cData[0].label}`;
          },
          label: (cData: TooltipItem<ChartType>) => {
            return `${numberToPercentageValue(cData.raw as number)}`;
          },
        },
      },
    },
  } as ChartOptions;
};

export const defaultPieChartConfig = (): ChartOptions => {
  return {
    responsive: true,
    radius: 100,
    cutout: "0%",
    elements: { arc: { borderWidth: 0 } },
    plugins: {
      legend: { maxWidth: 200, fullSize: false, labels: {}, itemWidth: 50 },
      tooltip: {
        callbacks: {
          title: (cData: TooltipItem<ChartType>[]) => {
            return `${cData[0].label}`;
          },
          label: (cData: TooltipItem<ChartType>) => {
            return `${numberToPercentageValue(cData.raw as number)}`;
          },
        },
      },
    },
  } as ChartOptions;
};
