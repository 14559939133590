
import { defineComponent, PropType, ref } from "vue";
import { MdCard } from "@/components/md/MdCard";
import AnnualFinancialDegreeOfCompletionTable from "@/views/degreeOfCompletion/components/AnnualFinances/AnnualFinancialDegreeOfCompletionTable.vue";
import AnnualFinancialInvoicedHourDistribution from "@/views/degreeOfCompletion/components/AnnualFinances/AnnualFinancialInvoicedHourDistribution.vue";
import AnnualFinancialOpenHourDistribution from "@/views/degreeOfCompletion/components/AnnualFinances/AnnualFinancialOpenHourDistribution.vue";
import AnnualFinancialDegreeOfCompletionKpi from "@/views/degreeOfCompletion/components/AnnualFinances/AnnualFinancialDegreeOfCompletionKpi.vue";
import AnnualFinancialDegreeOfCompletionEmployeeTable from "@/views/degreeOfCompletion/components/AnnualFinances/AnnualFinancialDegreeOfCompletionEmployeeTable.vue";

export default defineComponent({
  components: {
    MdCard,
    AnnualFinancialDegreeOfCompletionTable,
    AnnualFinancialInvoicedHourDistribution,
    AnnualFinancialOpenHourDistribution,
    AnnualFinancialDegreeOfCompletionKpi,
    AnnualFinancialDegreeOfCompletionEmployeeTable,
  },
  props: {
    year: { type: String },
    orderTypes: { type: Array as PropType<string[]>, required: true },
  },
  name: "AnnualFinances",
  setup(props, context) {
    const docTableExtended = ref(false as boolean);
    const docEmployeeTableExtended = ref(false as boolean);
    const extendTable = (
      id: number,
      empId: string,
      empName: string,
      empNumber: string
    ) => {
      context.emit("extendOverlay", id, empId, empName, empNumber);
    };

    return {
      docEmployeeTableExtended,
      docTableExtended,
      extendTable,
    };
  },
  emits: ["extendOverlay"],
});
