import { getSecure, toRequestParam } from "@/api/ApiUtil";

export const findAll = async (): Promise<ICorporateStructures[]> => {
  return (await getSecure("/corporateStructure")) as ICorporateStructures[];
};
export const updateCorporateStructureLeader = async (
  corporateStructureId: string,
  employeeId: string
) => {
  const param = toRequestParam({ employeeId: employeeId });
  return (await getSecure(
    `/corporateStructure/updateLeader/${corporateStructureId}/?${param}`
  )) as boolean;
};
