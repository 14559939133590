import { useStore } from "@/store/UserStore";

export const checkTeamPermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        [
          "ADMIN",
          "READ_TEAM_OWN",
          "READ_ESTABLISHMENT_OWN",
          "READ_CORPORATE_STRUCTURE_OWN",
          "READ_ALL",
        ].includes(val)
      )
    : false;
};

export const checkEstablishmentPermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        [
          "ADMIN",
          "READ_ESTABLISHMENT_OWN",
          "READ_CORPORATE_STRUCTURE_OWN",
          "READ_ALL",
        ].includes(val)
      )
    : false;
};

export const checkCorporateStrucutrePermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        ["ADMIN", "READ_CORPORATE_STRUCTURE_OWN", "READ_ALL"].includes(val)
      )
    : false;
};
export const checkReadAllPermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        ["ADMIN", "READ_ALL"].includes(val)
      )
    : false;
};
export const checkIfAdmin = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) => ["ADMIN"].includes(val))
    : false;
};

export const checkCorporateStrucutreUpdatePermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        ["ADMIN", "UPDATE_CORPORATE_STRUCTURE_OWN", "UPDATE_ALL"].includes(val)
      )
    : false;
};

export const checkEstablishmentUpdatePermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        [
          "ADMIN",
          "UPDATE_ESTABLISHMENT_OWN",
          "UPDATE_CORPORATE_STRUCTURE_OWN",
          "UPDATE_ALL",
        ].includes(val)
      )
    : false;
};
export const checkTeamUpdatePermissions = () => {
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        [
          "ADMIN",
          "UPDATE_ESTABLISHMENT_OWN",
          "UPDATE_TEAM_OWN",
          "UPDATE_CORPORATE_STRUCTURE_OWN",
          "UPDATE_ALL",
        ].includes(val)
      )
    : false;
};
export const checkEmployeeUpdatePermissions = async () => {
  if (useStore().user.internalId == "") {
    await useStore().getUser();
  }
  return useStore().user
    ? useStore().user.userRoles.some((val) =>
        [
          "ADMIN",
          "UPDATE_ESTABLISHMENT_OWN",
          "UPDATE_TEAM_OWN",
          "UPDATE_OWN",
          "UPDATE_CORPORATE_STRUCTURE_OWN",
          "UPDATE_ALL",
        ].includes(val)
      )
    : false;
};
