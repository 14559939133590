import { defineStore } from "pinia";
import { IUser } from "@/types/User";
import { getUser } from "@/api/UserApi";

interface State {
  token: string | null;
  user: IUser;
}

export const useStore = defineStore("userStore", {
  state: (): State => {
    return {
      token: localStorage.getItem("token"),
      user: {
        email: "",
        firstName: "",
        lastName: "",
        internalId: "",
        forceReset: false,
        userCorporateStructure: "",
        userCorporateStructureLabel: "",
        userEstablishment: "",
        userEstablishmentLabel: "",
        userTeam: "",
        userTeamLabel: "",
        userEmployee: "",
        userEmployeeLabel: "",
        userRoles: [],
      },
    };
  },
  actions: {
    async login(email: string, password: string): Promise<boolean> {
      try {
        const response = await fetch("/api/sign/in", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
        if (response.status === 200) {
          const body = await response.json();
          this.token = body.token;
          localStorage.setItem("token", body.token);
          this.user = await getUser();
          return true;
        } else {
          this.token = null;
          return false;
        }
      } catch (e) {
        this.token = null;
        return false;
      }
    },
    async logout() {
      localStorage.setItem("token", "");
      this.token = "";
      await fetch("/api/sign/out", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
    },
    async getUser() {
      this.user = await getUser();
    },
  },
});
