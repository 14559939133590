
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "@/store/FilterStore";
import DateRangeFilterButton from "./codin/DateRangeFilterButton.vue";
import ErrorPanel from "./codin/ErrorPanel.vue";
import { IMenuItem } from "@/components/md/MdMenu";
import MdFilterButton from "@/components/md/MdFilterButton/MdFilterButton.vue";
import TopNavBarButton from "@/components/codin/TopNavBarButton.vue";
import {
  checkCorporateStrucutrePermissions,
  checkEstablishmentPermissions,
  checkIfAdmin,
  checkReadAllPermissions,
  checkTeamPermissions,
} from "@/utils/PermissionChecker";

export default defineComponent({
  name: "GlobalTopNav",
  methods: {
    checkReadAllPermissions,
    checkIfAdmin,
    checkTeamPermissions,
    checkEstablishmentPermissions,
    checkCorporateStrucutrePermissions,
  },
  components: {
    TopNavBarButton,
    MdFilterButton,
    DateRangeFilterButton,
    ErrorPanel,
  },
  props: { onlyShowButton: Boolean },
  setup() {
    const filterStore = useStore();
    const corporateOptions = ref([] as IMenuItem[]);
    const corporatePreSelectedOptions = ref([] as IMenuItem[]);
    const establishmentOptions = ref([] as IMenuItem[]);
    const teamOptions = ref([] as IMenuItem[]);
    const employeeOptions = ref([] as IMenuItem[]);
    const fromDate = ref("" as string);
    const toDate = ref("" as string);
    const filterCheckboxValue = ref(true);

    const fetchInitialData = async () => {
      if (!filterStore.loaded) await filterStore.fetchData();
    };

    const createFilterArrays = () => {
      if (filterStore.loaded) {
        corporateOptions.value = filterStore.corporateStructures.map(
          (o) =>
            ({
              label: o.label,
              value: o.id,
              selected: filterStore.filter.corporateStructures.includes(o.id),
            } as IMenuItem)
        );
        establishmentOptions.value = filterStore.establishments.map(
          (o) =>
            ({
              label: o.label,
              value: o.id,
              selected: filterStore.filter.establishments.includes(o.id),
            } as IMenuItem)
        );
        teamOptions.value = filterStore.teams.map(
          (o) =>
            ({
              label: o.label,
              value: o.id,
              selected: filterStore.filter.teams.includes(o.id),
            } as IMenuItem)
        );
        employeeOptions.value = filterStore.employees.map((o) => {
          return {
            label: o.label,
            value: o.id,
            active: o.active,
            selected: filterStore.filter.employees.includes(o.id),
          } as IMenuItem;
        });
        fromDate.value = filterStore.filter.from;
        toDate.value = filterStore.filter.to;
      }
    };

    createFilterArrays();

    const employeeOptionsFiltered = computed((): IMenuItem[] => {
      return employeeOptions.value.filter((o) => {
        if (filterCheckboxValue.value) {
          return o.active;
        } else {
          return true;
        }
      });
    });

    watch(() => filterStore.loaded, createFilterArrays, {
      deep: true,
      immediate: true,
    });
    filterStore.$onAction((context) => {
      if (context.name === "updateFilter") {
        createFilterArrays();
      }
    }, false);

    const updateCorporateStructures = (chosenCorpIds: IMenuItem[]) => {
      filterStore.updateCorporateStructures(
        chosenCorpIds.filter((o) => o.selected).map((o) => o.value as string)
      );
    };

    const updateEstablishments = (chosenEstablishmentIds: IMenuItem[]) => {
      filterStore.updateEstablishments(
        chosenEstablishmentIds
          .filter((o) => o.selected)
          .map((o) => o.value as string)
      );
    };

    const updateTeams = (chosenTeamIds: IMenuItem[]) => {
      filterStore.updateTeams(
        chosenTeamIds.filter((o) => o.selected).map((o) => o.value as string)
      );
    };

    const updateEmployees = (chosenEmployeeIds: IMenuItem[]) => {
      filterStore.updateEmployees(
        chosenEmployeeIds
          .filter((o) => o.selected)
          .map((o) => o.value as string)
      );
    };

    const updateDate = (newFromDate: string, newToDate: string) => {
      filterStore.updateDate(newFromDate, newToDate);
      fromDate.value = newFromDate;
      toDate.value = newToDate;
    };
    return {
      corporateOptions,
      corporatePreSelectedOptions,
      updateCorporateStructures,
      establishmentOptions,
      updateEstablishments,
      teamOptions,
      updateTeams,
      employeeOptionsFiltered,
      updateEmployees,
      fromDate,
      toDate,
      updateDate,
      fetchInitialData,
      filterCheckboxValue,
    };
  },
  mounted() {
    this.fetchInitialData();
  },
});
