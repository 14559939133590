
import { defineComponent, PropType } from "vue";
import MdAvatarMixin from "./MdAvatarMixin";

export default defineComponent({
  name: "MdAvatar",
  mixins: [MdAvatarMixin],
  props: {
    variant: {
      type: String as PropType<"monogram" | "image" | "icon">,
      default: "monogram",
    },
    content: {
      type: String,
      default: "A",
    },
    raw: String,
  },
  data() {
    return {};
  },
  computed: {
    initials(): string {
      return this.content.toUpperCase();
    },
  },
});
