import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "row mb-4" }
const _hoisted_6 = { class: "col-9" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "row mb-4" }
const _hoisted_9 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_annual_financial_invoiced_hour_distribution = _resolveComponent("annual-financial-invoiced-hour-distribution")!
  const _component_md_card = _resolveComponent("md-card")!
  const _component_annual_financial_open_hour_distribution = _resolveComponent("annual-financial-open-hour-distribution")!
  const _component_annual_financial_degree_of_completion_table = _resolveComponent("annual-financial-degree-of-completion-table")!
  const _component_annual_financial_degree_of_completion_kpi = _resolveComponent("annual-financial-degree-of-completion-kpi")!
  const _component_annual_financial_degree_of_completion_employee_table = _resolveComponent("annual-financial-degree-of-completion-employee-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_md_card, {
          class: "distribution-invoiced",
          variant: "elevated"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_annual_financial_invoiced_hour_distribution, {
              chartHeight: "140",
              year: _ctx.year,
              "order-types": _ctx.orderTypes
            }, null, 8, ["year", "order-types"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_md_card, {
          class: "distribution-open",
          variant: "elevated"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_annual_financial_open_hour_distribution, {
              chartHeight: "140",
              year: _ctx.year,
              "order-types": _ctx.orderTypes
            }, null, 8, ["year", "order-types"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_annual_financial_degree_of_completion_table, {
          year: _ctx.year,
          "order-types": _ctx.orderTypes,
          class: "one",
          onExtendOverlay: _ctx.extendTable
        }, null, 8, ["year", "order-types", "onExtendOverlay"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_annual_financial_degree_of_completion_kpi, {
          year: _ctx.year,
          "order-types": _ctx.orderTypes
        }, null, 8, ["year", "order-types"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_annual_financial_degree_of_completion_employee_table, {
          year: _ctx.year,
          "order-types": _ctx.orderTypes,
          onExtendOverlay: _ctx.extendTable
        }, null, 8, ["year", "order-types", "onExtendOverlay"])
      ])
    ])
  ]))
}