import { ChartOptions } from "chart.js";

export interface IChartDataWrapper {
  chartData: IChartData;
  chartOptions: ChartOptions;
}

interface IChartData {
  labels: (string | number)[] | (string | number)[][];
  datasets: IChartDataSet[];
}

interface IChartDataSet {
  barThickness?: number;
  minBarLength?: number;
  label?: string | number | (string | number)[];
  backgroundColor: string | string[];
  data: number[];
  meta?: (boolean | string | number)[];
  hoverOffset?: number;
}

export const defaultValues = {
  barThickness: 24,
  minBarLength: 5,
};
