
import { defineComponent, PropType } from "vue";
import { ColumnTypes, FormatTypes } from "./types";

export default defineComponent({
  name: "MdTableColumn",
  props: {
    topTitle: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    display: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<ColumnTypes>,
      default: () => {
        return "raw";
      },
    },
    backgroundColor: {
      type: String,
      default: () => {
        return "$m3-surface";
      },
    },
    textColor: {
      type: String,
      default: () => {
        return "$m3-surface";
      },
    },
    format: {
      type: String as PropType<FormatTypes>,
      default: () => {
        return "string";
      },
    },
    numeric: { type: Boolean, default: false },
    width: Number,
    html: {
      type: Boolean,
      default: false,
    },
  },
});
