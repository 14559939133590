
import { defineComponent, PropType } from "vue";
import MdButtonMixin from "../MdButton/MdButtonMixin";

export default defineComponent({
  name: "MdFabExtended",
  mixins: [MdButtonMixin],
  props: {
    color: {
      type: String as PropType<
        "primary" | "secondary" | "tertiary" | "surface"
      >,
      required: false,
      default: () => {
        return "primary";
      },
    },
    icon: {
      type: String,
      required: false,
    },
    notExtended: Boolean,
  },
});
