
import { defineComponent, PropType, ref, VNode, watch } from "vue";
import { FormatTypes, IRow } from "./index";
import MdButton from "../MdButton/MdButton.vue";
import { MdCheckbox } from "../MdCheckbox";
import { MdBadge } from "../MdBadge";
import { ColumnTypes } from "./types";
import { MdMenu, TransitionMdMenu } from "@/components/md/MdMenu";
import { TransitionFade } from "@/components/cc/Transition";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdAvatar } from "../MdAvatar";
import {
  MdProgressIndicatorLinear,
  TransitionMdProgressIndicatorLinear,
} from "@/components/md/MdProgressIndicator";
import MdTableFooter from "@/components/md/MdTable/MdTableFooter.vue";
import MdTableSearchHeader from "@/components/md/MdTable/MdTableSearchHeader.vue";
import {
  numberToCurrencyDifferenceValue,
  numberToCurrencyValue,
  numberToHourDifferenceValue,
  numberToHourValue,
  numberToPercentageValue,
} from "@/utils/NumberFormatUtil";
import { useStore } from "@/store/ColorStore";
import CellLoaderRaw from "@/components/md/MdTable/CellLoaderRaw.vue";
import MdAvatarLoader from "@/components/md/MdAvatar/MdAvatarLoader.vue";
import CellLoaderBox from "@/components/md/MdTable/CellLoaderBox.vue";
import { IPaginationParameters } from "@/types/PaginationParameters";
import { ITableOptions } from "@/types/TableOptions";
import { formatDate } from "@/utils/DateHelper";

interface MdTableColumnProps {
  topTitle: string;
  title: string;
  display: string;
  numeric: boolean;
  format: FormatTypes;
  type: ColumnTypes;
  width?: number;
  backgroundColor?: string;
  textColor?: string;
}

interface IRowOptions {
  menuVisible: boolean;
  hovered: boolean;
  selected: boolean;
}

export default defineComponent({
  name: "MdTable",
  components: {
    MdTableSearchHeader,
    MdCheckbox,
    MdButton,
    MdMenu,
    TransitionMdMenu,
    TransitionFade,
    MdTooltip,
    MdAvatar,
    MdProgressIndicatorLinear,
    TransitionMdProgressIndicatorLinear,
    MdBadge,
    MdTableFooter,
    CellLoaderRaw,
    MdAvatarLoader,
    CellLoaderBox,
  },
  props: {
    data: {
      type: Array as PropType<IRow[]>,
      default: () => {
        return [{ key: "o" } as IRow];
      },
    },
    sum: {
      type: Object as PropType<IRow> | null,
      default: null,
    },
    tableOptions: {
      type: Object as PropType<ITableOptions>,
      required: true,
    },
    selectable: Boolean,
    clickable: Boolean,
    header: {
      type: Boolean,
      default: true,
    },
    skeletonRows: {
      type: Number,
      default: 7,
    },
    sortable: Boolean,
    searchHeader: Boolean,
    pagination: Boolean,
    loading: Boolean,
    maxHeight: {
      type: Number,
      default: () => 310.5,
    },
    minHeight: {
      type: Number,
      default: () => 100,
    },
    showActiveTableButton: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showNonUserEmployees: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showNonUserEmployeeFilter: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    resizable: { type: Boolean, required: false, default: () => false },
    downloadLink: { type: String, required: false, default: () => "" },
  },
  emits: ["click", "buttonClick", "menuItemClick", "fetchData"],
  setup(props, { slots }) {
    const defaultSlot = slots.default;
    const hasSum = ref(false as boolean);
    const showingActiveEmployees = ref(false as boolean);
    const showingNonUsers = ref(false as boolean);
    const columns = ref([] as MdTableColumnProps[]);
    if (defaultSlot) {
      columns.value = defaultSlot().map(
        (column: VNode) => column.props as MdTableColumnProps
      );
      columns.value.forEach((column) => {
        // Set default type if not set
        if (!column.type) column.type = "raw";
      });
      columns.value.forEach((column) => {
        // Set default format if not set
        if (!column.format) column.format = "string";
      });
      columns.value.forEach((column) => {
        // Set default background if not set
        if (!column.backgroundColor)
          column.backgroundColor = useStore().surfaceColor;
      });
    }
    if (defaultSlot) {
      watch(
        () => defaultSlot(),
        () => {
          columns.value = defaultSlot().map(
            (column: VNode) => column.props as MdTableColumnProps
          );
          columns.value.forEach((column) => {
            // Set default type if not set
            if (!column.type) column.type = "raw";
          });
          columns.value.forEach((column) => {
            // Set default format if not set
            if (!column.format) column.format = "string";
          });
          columns.value.forEach((column) => {
            // Set default background if not set
            if (!column.backgroundColor)
              column.backgroundColor = useStore().surfaceColor;
          });
        }
      );
    }

    return {
      columns,
      hasSum,
      showingActiveEmployees,
      showingNonUsers,
    };
  },
  created() {
    this.updateRowOptions(this.rows);
    window.addEventListener("click", this.handleDocumentClick);
  },
  mounted() {
    this.rows = this.data as IRow[];
  },
  data() {
    return {
      rows: [] as IRow[],
      headCheckbox: false,
      rowOptions: [] as IRowOptions[],
      menuOpenButtonClicked: false,
      sortColumn: "",
      sortDirection: "asc",
      query: "",
      rowsPerPage: 0,
      page: 1,
      totalRows: 0,
      totalPages: 0,
    };
  },
  watch: {
    data: {
      handler(newValue: IRow[]) {
        this.rows = newValue;
      },
      deep: true,
    },
    tableOptions: {
      handler(newValue: ITableOptions) {
        this.rowsPerPage = newValue.size;
        this.page = newValue.page;
        this.totalRows = newValue.totalElements ? newValue.totalElements : 0;
        this.totalPages = newValue.totalPages ? newValue.totalPages : 0;
        this.sortColumn = newValue.sort.field;
        this.sortDirection = newValue.sort.direction;
        this.query = newValue.search;
      },
      deep: true,
    },
    headCheckbox(newValue: boolean) {
      this.rows.forEach((row) => {
        row._selected = newValue;
      });
    },
    rows(rows: IRow[]) {
      this.hasSum = !!(this.sum && Object.keys(this.sum).length > 0);
      this.resetRowOptions(rows);
    },
  },
  computed: {
    selectedRows(): number {
      const rows = this.rows.filter((row) => row._selected === true);
      return rows.length;
    },
    nextPageExists(): boolean {
      return this.totalRows > this.page * this.rowsPerPage + this.rowsPerPage;
    },
  },
  methods: {
    formatNumber(num: number, format: string): string {
      switch (format) {
        case "currency":
          return numberToCurrencyValue(num);
        case "currencyDiff":
          return numberToCurrencyDifferenceValue(num);
        case "hour":
          return numberToHourValue(num);
        case "hourDiff":
          return numberToHourDifferenceValue(num);
        case "percentage":
          return numberToPercentageValue(num);
        default:
          return num.toString();
      }
    },
    formatToDate(dateString: string): string {
      return formatDate(dateString);
    },
    updatePageSize(size: number) {
      if (this.totalRows && size * this.page >= this.totalRows) {
        this.page = 0;
      }
      const param: IPaginationParameters = {
        page: this.page,
        size: size,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
      };
      this.$emit("fetchData", param);
    },
    toggleShowActive(showActive: boolean) {
      this.showingActiveEmployees = showActive;
      const param: IPaginationParameters = {
        page: this.page,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
      };
      this.$emit("fetchData", param);
    },
    toggleShowNonUsers(showNonUsers: boolean, showActive: boolean) {
      this.showingNonUsers = showNonUsers;
      this.showingActiveEmployees = showActive;
      const param: IPaginationParameters = {
        page: this.page,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showNonUsers: this.showingNonUsers,
        showActive: showActive,
      };
      this.$emit("fetchData", param);
    },
    onNext() {
      const param: IPaginationParameters = {
        page: this.page + 1,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };
      this.$emit("fetchData", param);
    },
    onPrev() {
      const param: IPaginationParameters = {
        page: this.page - 1,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };
      this.$emit("fetchData", param);
    },
    onFirstPage() {
      const param: IPaginationParameters = {
        page: 0,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };
      this.$emit("fetchData", param);
    },
    onLastPage() {
      const param: IPaginationParameters = {
        page: this.totalPages - 1,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };
      this.$emit("fetchData", param);
    },
    rowClick(row: IRow, index: number, columnName: string) {
      this.$emit("click", row, index, columnName);
    },
    updateRowOptions(rows: IRow[]) {
      this.resetRowOptions(rows);
    },
    resetRowOptions(rows: IRow[]) {
      this.rowOptions = [] as IRowOptions[];
      rows.forEach(() => {
        this.rowOptions.push({
          menuVisible: false,
          hovered: false,
          selected: false,
        });
      });
    },
    clearSelection() {
      this.rows.forEach((row) => {
        row._selected = false;
      });
    },
    openMenu(rowIndex: number) {
      this.menuOpenButtonClicked = true;
      this.rowOptions[rowIndex].menuVisible = true;
      setTimeout(() => {
        this.menuOpenButtonClicked = false;
      }, 100);
    },
    handleDocumentClick() {
      if (!this.menuOpenButtonClicked) this.resetRowOptions(this.rows);
    },
    buttonClick(columnName: string, row: IRow, index: number) {
      this.$emit("buttonClick", columnName, row, index);
    },
    onSort(columnName: string) {
      if (!this.sortable) return;
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortDirection = "asc";
        this.sortColumn = columnName;
      }
      const param: IPaginationParameters = {
        page: this.page,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };

      this.$emit("fetchData", param);
    },
    onSearch(searchValue: string) {
      this.query = searchValue;
      const param: IPaginationParameters = {
        page: this.page,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };
      this.$emit("fetchData", param);
    },
    onReset() {
      this.query = "";
      this.page = 0;
      this.rowsPerPage = 5;
      this.sortDirection = "asc";
      this.sortColumn = "internal_id";
      this.showingActiveEmployees = false;
      const param: IPaginationParameters = {
        page: 0,
        size: this.rowsPerPage,
        sort: {
          field: this.sortColumn,
          direction: this.sortDirection,
        },
        search: this.query,
        showActive: this.showingActiveEmployees,
        showNonUsers: this.showingNonUsers,
      };
      this.$emit("fetchData", param);
    },
  },
  unmounted() {
    window.removeEventListener("click", this.handleDocumentClick);
  },
});
