
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TransitionMdMenu",
  props: {
    origin: {
      type: String as PropType<"top" | "bottom" | "top_right">, //TODO add more origins if required
      default: "top",
    },
  },
});
