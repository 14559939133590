import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<"outlined" | "elevated" | "flat">,
      required: false,
      default: () => {
        return "outlined";
      },
    },
    disabled: Boolean,
  },
});
