
import { defineComponent, ref, watch } from "vue";
import { MdTable, MdTableColumn } from "@/components/md/MdTable";
import { useStore } from "@/store/FilterStore";
import { IPaginationInterface } from "@/types/PaginationInterface";
import { findAllAnnualFinancialEmployeeTable } from "@/api/DegreeOfCompletionApi";
import { IAnnualFinancialEmployeeTable } from "@/types/degreeOfCompletion/EmployeeDegreeOfCompletionTable";
import { IPaginationParameters } from "@/types/PaginationParameters";
import { ITableOptions } from "@/types/TableOptions";
import { toTableOptions } from "@/components/md/MdTable/TableOptionsBuilder";
import { toRequestParam } from "@/api/ApiUtil";

export default defineComponent({
  components: { MdTable, MdTableColumn },
  name: "AnnualFinancialDegreeOfCompletionEmployeeTable",
  props: {
    year: { type: String, default: "" },
    orderTypes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props, context) {
    const loading = ref(true as boolean);
    const rows = ref([] as IAnnualFinancialEmployeeTable[]);
    const sum = ref({} as IAnnualFinancialEmployeeTable | null);
    const defaultPaginationParameter = {
      page: 0,
      size: 5,
      sort: {
        field: "orderEmployeeNumber",
        direction: "asc",
      },
      search: "",
    } as IPaginationParameters;
    const tableOptions = ref({
      ...defaultPaginationParameter,
    } as ITableOptions);

    const store = useStore();
    watch(
      () => store.loaded,
      () => {
        if (store.loaded) {
          fetchData(null);
        }
      }
    );
    watch(
      () => props.year,
      () => {
        fetchData(null);
      }
    );
    watch(
      () => props.orderTypes,
      () => {
        fetchData(null);
      }
    );
    const extendOverlay = (row: number, index: number) => {
      context.emit(
        "extendOverlay",
        "AFDOCE",
        rows.value[index].orderEmployeeId,
        rows.value[index].orderEmployeeDisplayName,
        rows.value[index].orderEmployeeDisplayNumber
      );
    };
    const fetchData = async (
      paginationParameter: IPaginationParameters | null
    ) => {
      loading.value = true;
      const tempPaginationParameter = paginationParameter
        ? paginationParameter
        : defaultPaginationParameter;
      const temp: IPaginationInterface<IAnnualFinancialEmployeeTable> =
        await findAllAnnualFinancialEmployeeTable(
          tempPaginationParameter,
          props.year,
          props.orderTypes as string[]
        );
      rows.value = temp.content;
      if (temp.sum) {
        sum.value = temp.sum;
      } else {
        sum.value = null;
      }
      loading.value = false;
      tableOptions.value = toTableOptions(temp, tempPaginationParameter);
    };
    return {
      rows,
      fetchData,
      extendOverlay,
      sum,
      tableOptions,
      loading,
      toRequestParam,
    };
  },
  mounted() {
    const filterStore = useStore();
    if (filterStore.loaded) {
      this.fetchData(null);
    }
    filterStore.$onAction((context) => {
      if (context.name === "updateFilter") {
        this.fetchData(null);
      }
    }, false);
  },
});
