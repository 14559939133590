
import { defineComponent, PropType } from "vue";
import MdButtonMixin from "../MdButton/MdButtonMixin";

export default defineComponent({
  name: "MdFab",
  mixins: [MdButtonMixin],
  props: {
    size: {
      type: String as PropType<"medium" | "large" | "small">,
      required: false,
      default: () => {
        return "medium";
      },
    },
    color: {
      type: String as PropType<
        "primary" | "secondary" | "tertiary" | "surface"
      >,
      required: false,
      default: () => {
        return "primary";
      },
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
});
