import { getSecure, toRequestParam } from "@/api/ApiUtil";

export const findAll = async (): Promise<IEstablishment[]> => {
  return (await getSecure("/establishment")) as IEstablishment[];
};
export const updateEstablishmentLeader = async (
  establishmentId: string,
  employeeId: string
) => {
  const param = toRequestParam({ employeeId: employeeId });
  return (await getSecure(
    `/establishment/updateLeader/${establishmentId}/?${param}`
  )) as boolean;
};
