
import { defineComponent, onMounted, ref } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import { checkEmployeeUpdatePermissions } from "@/utils/PermissionChecker";
import { useStore } from "@/store/UserStore";

export default defineComponent({
  name: "SidebarComponent",
  components: { MdButton },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const topItems = [
      { icon: "dashboard", title: "Dashboard", pathName: "Dashboard" },
      {
        title: "Umsatz",
      },
      {
        icon: "compare_arrows",
        title: "Plan-Ist-Vergleich",
        pathName: "TargetComparison",
      },
      {
        icon: "calendar_month",
        title: "Jahresvergleich",
        pathName: "MultipleYearTargetComparison",
      },
      {
        icon: "pie_chart",
        title: "Umsatzverteilung",
        pathName: "TurnoverDistribution",
      },
      // {
      //   icon: "euro_sign",
      //   title: "Durchschnittsumsatz",
      //   pathName: "AvgTurnover",
      // },
      {
        title: "Planung",
      },
      {
        icon: "assignment",
        title: "Offene Planstunden",
        pathName: "OpenPlannedHours",
      },
      {
        icon: "assignment_late",
        title: "Halbfertige Arbeiten",
        pathName: "WorkInProgress",
      },
      {
        icon: "assignment_turned_in",
        title: "Fertigstellungsgrad",
        pathName: "DegreeOfCompletion",
      },
      {
        icon: "schedule",
        title: "Zeitverteilung",
        pathName: "TimeDistribution",
      },
      {
        icon: "plagiarism",
        title: "Auftragsanalyse",
        pathName: "ContributionMargin",
      },
    ] as Array<MenuItem>;
    const bottomItems = ref([] as Array<MenuItem>);
    onMounted(async () => {
      await useStore().getUser();
      bottomItems.value = (await checkEmployeeUpdatePermissions())
        ? ([
            {
              // the hr renders above the title. So if there should be no
              // title, there needs to be an empty one
              title: "",
            },
            {
              icon: "admin_panel_settings",
              title: "Admin Center",
              pathName: "AdminCenter",
            },
            {
              icon: "settings",
              title: "Einstellungen",
              pathName: "Settings",
            },
          ] as Array<MenuItem>)
        : ([
            {
              // the hr renders above the title. So if there should be no
              // title, there needs to be an empty one
              title: "",
            },
            {
              icon: "settings",
              title: "Einstellungen",
              pathName: "Settings",
            },
          ] as Array<MenuItem>);
    });
    return {
      topItems,
      bottomItems,
    };
  },
  methods: {
    toggleMenuCollapse() {
      this.$emit("collapse");
    },
  },
  emits: ["collapse"],
});

interface MenuItem {
  title: string;
  icon: string | null;
  pathName: string | null;
}
