import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_table, {
      clickable: true,
      data: _ctx.rows,
      "search-header": true,
      "min-height": 165,
      sortable: false,
      "table-options": _ctx.tableOptions,
      loading: _ctx.loading,
      "download-link": 
        '/degreeOfCompletion/annualFinancialDegreeOfCompletionTable/assessmentYear/' +
        _ctx.year +
        '/excel?' +
        _ctx.toRequestParam({ orderType: _ctx.orderTypes })
      ,
      onFetchData: _ctx.fetchData,
      onClick: _ctx.extendOverlay
    }, {
      default: _withCtx(() => [
        _createVNode(_component_md_table_column, {
          title: "Status",
          display: "status"
        }),
        _createVNode(_component_md_table_column, {
          title: "Anzahl Aufträge",
          display: "orders",
          numeric: true
        }),
        _createVNode(_component_md_table_column, {
          numeric: true,
          title: "Erfasste Stunden",
          display: "timeUnits",
          format: "hour"
        }),
        _createVNode(_component_md_table_column, {
          numeric: true,
          title: "Planstunden",
          display: "plannedHours",
          format: "hour"
        }),
        _createVNode(_component_md_table_column, {
          numeric: true,
          title: "Differenz",
          display: "difference",
          format: "hourDiff"
        })
      ]),
      _: 1
    }, 8, ["data", "table-options", "loading", "download-link", "onFetchData", "onClick"])
  ]))
}