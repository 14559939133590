import { useStore } from "@/store/UserStore";

export const getSecure = async (url: string): Promise<unknown> => {
  const userStore = useStore();
  if (userStore.token) {
    const token = "Bearer " + userStore.token;
    return fetch("/sapi" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((o: Response) => {
      //logs out user if token is expired, or incorrect and we get an
      //401 or 403"
      const json = o.json();
      if (o.status === 401) {
        userStore.token = null;
        throw new Error("user not logged in");
      } else if (o.status === 403) {
        userStore.token = null;
        throw new Error("user not logged in");
      }
      return json;
    });
  }
};

export const postSecure = async (
  url: string,
  payload: string
): Promise<unknown> => {
  const userStore = useStore();
  if (userStore.token) {
    const token = "Bearer " + userStore.token;
    return await fetch("/sapi" + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: payload,
    }).then((o: Response) => {
      //logs out user if token is expired, or incorrect and we get an
      //401 or 403"
      const json = o.json();
      if (o.status === 401) {
        userStore.token = null;
      } else if (o.status === 403) {
        userStore.token = null;
      }
      return json;
    });
  }
};

export const downloadSecure = async (
  url: string,
  payload: string
): Promise<void> => {
  const userStore = useStore();
  if (userStore.token) {
    const token = "Bearer " + userStore.token;
    const response = await fetch("/sapi" + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: payload,
    });
    //logs out user if token is expired, or incorrect and we get an
    //401 or 403"
    if (response.status === 401) {
      userStore.token = null;
    } else if (response.status === 403) {
      userStore.token = null;
    }
    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      const fileNameHeader = response.headers.get("Content-Disposition");
      const match = /filename="([^"]+)"/.exec(fileNameHeader || "");
      const fileName = (match && match[1]) || "kantagram.xlsx";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    }
  }
};

export const post = async (url: string, payload: string): Promise<unknown> => {
  return await fetch("/api" + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: payload,
  }).then((o: Response) => o.json());
};

export const putSecure = async (
  url: string,
  payload: string
): Promise<unknown> => {
  const userStore = useStore();
  if (userStore.token) {
    const token = "Bearer " + userStore.token;
    return await fetch("/sapi" + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: payload,
    }).then((o: Response) => {
      //logs out user if token is expired, or incorrect and we get an
      //401 or 403"
      const json = o.json();
      if (o.status === 401) {
        userStore.token = null;
      } else if (o.status === 403) {
        userStore.token = null;
      }
      return json;
    });
  }
};
export const deleteSecure = async (url: string): Promise<void> => {
  const userStore = useStore();
  if (userStore.token) {
    const token = "Bearer " + userStore.token;
    const o = await fetch("/sapi" + url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (o.status == 204) {
      return;
    } else {
      throw o.json();
    }
  }
};

export const toRequestParam = (object: object): string => {
  const val = Object.entries(object)
    .map(([key, val]) => {
      if (val instanceof Array) {
        return val.map((o) => `${key}=${o}`).join("&");
      } else {
        return `${key}=${val}`;
      }
    })
    .join("&");
  return encodeURI(val);
};
