import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { registerGlobalComponents } from "./global-components";
import { createPinia } from "pinia";

const pinia = createPinia();
const app = createApp(App);
registerGlobalComponents(app);
// a solution to close overlays by clicking outside
// used on eg FilterSelect
app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.use(router).use(pinia).mount("#app");
