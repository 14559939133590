import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  RouterScrollBehavior,
} from "vue-router";
import AuthGuard from "@/router/AuthGuard";
import AnnualFinances from "@/views/degreeOfCompletion/tabs/AnnualFinances.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "dashboard-view" */ "../views/dashboard/Dashboard.vue"
      ),
  },
  {
    name: "TargetComparison",
    path: "/target-comparison",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "target-comparison" */ "../views/targetComparison/TargetComparison.vue"
      ),
  },
  {
    name: "MultipleYearTargetComparison",
    path: "/multiple-year-target-comparison",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "multiple-year-target-comparison" */ "../views/multipleYearTargetComparison/MultipleYearTargetComparison.vue"
      ),
  },
  {
    name: "TurnoverDistribution",
    path: "/turnover-distribution",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "turnover-distribution" */ "../views/turnoverDistribution/TurnoverDistribution.vue"
      ),
  },
  {
    name: "TDOrdertypeOverlayOrders",
    path: "/turnover-distribution/ordertype-overlay/orders",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "ordertype-overlay-orders" */ "../views/turnoverDistribution/components/overlays/OrderTypeOverlay/Orders/AvgTurnoverKpi.vue"
      ),
  },
  {
    name: "AvgTurnover",
    path: "/avg-turnover",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "avg-turnover" */ "../views/avgTurnover/AvgTurnover.vue"
      ),
  },
  {
    name: "OpenPlannedHours",
    path: "/open-planned-hours",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "open-planned-hours" */ "../views/openPlannedHours/OpenPlannedHours.vue"
      ),
  },
  {
    name: "DegreeOfCompletion",
    path: "/degree-of-completion",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "degree-of-completion" */ "../views/degreeOfCompletion/DegreeOfCompletion.vue"
      ),
    children: [
      {
        path: "/degree-of-completion/annualFinances",
        name: "AnnualFinances",
        component: AnnualFinances,
      },
    ],
  },
  {
    name: "TimeDistribution",
    path: "/time-distribution",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "time-distribution" */ "../views/timeDistribution/TimeDistribution.vue"
      ),
  },
  {
    name: "ContributionMargin",
    path: "/contribution-margin",
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "contribution-margin" */ "../views/contributionMargin/ContributionMargin.vue"
      ),
  },
  {
    name: "WorkInProgress",
    path: "/work-in-progress",

    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "work-in-progress" */ "../views/workInProgress/WorkInProgress.vue"
      ),
  },
  {
    name: "AdminCenter",
    path: "/admin-center/:subroute?",
    meta: {
      hideTopnav: true,
    },
    beforeEnter: AuthGuard,
    component: () => import("../views/adminCenter/AdminCenter.vue"),
    props: true,
  },
  {
    name: "SignIn",
    path: "/sign-in",
    meta: {
      fullscreen: true,
    },
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../views/sign/SignIn.vue"),
  },
  {
    name: "PasswordRecovery",
    path: "/password-recovery",
    meta: {
      fullscreen: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "password-recovery" */ "../views/sign/PasswordRecovery.vue"
      ),
  },
  {
    name: "Settings",
    path: "/settings",
    meta: {
      hideTopnav: true,
    },
    beforeEnter: AuthGuard,
    component: () =>
      import(
        /* webpackChunkName: "user-settings" */ "../views/userSettings/UserSettings.vue"
      ),
  },
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      return { path: "/" };
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: (() => {
    return { top: 0 };
  }) as RouterScrollBehavior,
  routes,
});

export default router;
