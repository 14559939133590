import { defineStore } from "pinia";

interface State {
  chartColors: string[];
}

export const useStore = defineStore("chartColorStore", {
  state: (): State => {
    return {
      chartColors: [
        getComputedStyle(document.body).getPropertyValue("--chart-color-0"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-1"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-2"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-3"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-4"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-5"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-6"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-7"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-8"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-9"),
        getComputedStyle(document.body).getPropertyValue("--chart-color-10"),
      ],
    };
  },
});
