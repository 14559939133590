
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton";
import { MdFab, MdFabExtended } from "@/components/md/MdFab";
import { IMenuItem, ISidebarFAB } from ".";

export default defineComponent({
  components: {
    MdButton,
    MdFabExtended,
    MdFab,
  },
  name: "MdNavigationDrawer",
  setup() {
    return {};
  },
  props: {
    menu: {
      required: true,
      type: Array as PropType<IMenuItem[]>,
    },
    routerName: String,
    fab: Object as PropType<ISidebarFAB>,
  },
  emits: ["item-click", "collapse"],
  data() {
    return {
      rail: false,
      activeMenu: this.$router.currentRoute,
      collapsedClassName: "",
    };
  },
  methods: {
    toggleDrawerRail() {
      if (this.collapsedClassName == "") {
        this.collapsedClassName = "md-navigation-rail";
      } else {
        this.collapsedClassName = "";
      }
    },
  },
  computed: {},
});
