export const numberToValue = (
  number: number | null,
  suffix: string,
  decimalPlaces = 2
): string => {
  if (number === null) {
    return "-/-";
  } else {
    const num = number.toFixed(decimalPlaces);
    return (
      num
        .replace(".", ",")
        .replace("-", "-\xa0")
        .replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g, "g"), "$&" + ".") + suffix
    );
  }
};

export const numberToCurrencyValue = (number: number | null): string => {
  return numberToValue(number, "\xa0€");
};

export const numberToHourValue = (number: number | null): string => {
  return numberToValue(number, "\xa0Std.");
};
export const numberToPercentageValue = (number: number | null): string => {
  return numberToValue(number, "\xa0%");
};

export const numberToCurrencyDifferenceValue = (
  number: number | null
): string => {
  if (!number && number !== 0) {
    return "";
  } else if (number > 0) {
    return "+\xa0" + numberToValue(number, "\xa0€");
  } else {
    return numberToValue(number, "\xa0€");
  }
};

export const numberToHourDifferenceValue = (number: number | null): string => {
  if (!number && number !== 0) {
    return "";
  } else if (number > 0) {
    return "+ " + numberToValue(number, "\xa0Std.");
  } else {
    return numberToValue(number, "\xa0Std.");
  }
};
