import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String as PropType<"medium" | "small" | "large">,
      default: "medium",
    },
  },
});
