
import { defineComponent } from "vue";
import MdChipMixin from "./MdChipMixin";

export default defineComponent({
  name: "MdInputChip",
  mixins: [MdChipMixin],
  props: {
    icon: {
      type: String,
      required: false,
    },
    avatar: {
      type: String,
      required: false,
    },
    closable: Boolean,
    resettable: Boolean,
    selected: Boolean,
  },
  data() {
    return {
      hidden: false,
      chipIsSelected: this.selected || false,
    };
  },
  beforeMount() {
    this.chipIsSelected = this.selected || false;
  },
  computed: {
    isSelected(): boolean {
      return this.chipIsSelected;
    },
  },
  watch: {
    selected(newValue) {
      this.chipIsSelected = newValue;
    },
  },
  emits: ["close", "toggle", "reset"],
  methods: {
    toggle() {
      if (this.disabled) return;
      this.chipIsSelected = !this.chipIsSelected;
      this.$emit("toggle", this.isSelected);
    },
    close() {
      if (this.disabled) return;
      this.$emit("close");
      this.hidden = true;
    },
    reset() {
      if (this.disabled) return;
      this.$emit("reset");
    },
  },
});
