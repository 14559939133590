
import { defineComponent, PropType } from "vue";
import MdInputMixin from "@/components/md/MdInput/MdInputMixin";
import { IMenuItem } from "@/components/md/MdMenu";
import MdInputField from "@/components/md/MdInput/MdInputField.vue";
import MdMultiSelectItem from "@/components/md/MdInput/MdMultiSelectItem.vue";
import _ from "lodash";

export default defineComponent({
  name: "MdMultiSelect",
  components: { MdMultiSelectItem, MdInputField },
  props: {
    placeholder: { type: String, required: true },
    searchable: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
    singleSelection: { type: Boolean }
  },
  mixins: [MdInputMixin],
  emits: ["update:modelValue", "change", "trailing-icon-click"],
  data() {
    return {
      inputText: "",
    };
  },
  computed: {
    results(): IMenuItem[] {
      const val = _.cloneDeep(this.modelValue);
      if (this.inputText.length === 0) {
        return val;
      } else {
        return val.filter((o) =>
          o.label.toLowerCase().trim().includes(this.inputText.toLowerCase())
        );
      }
    },
  },
  methods: {
    focusOut() {
      if (!this.hasValue) {
        this.inputText = "";
      }
    },
    clearValues() {
      this.inputText = "";
      this.hasValue = false;
      const tempItems = _.cloneDeep(this.modelValue);
      for (const tempItem of tempItems) {
        tempItem.selected = false;
      }
      this.$emit("update:modelValue", tempItems);
      this.$emit("change");
    },
    itemChanged(item: IMenuItem) {
      const tempItems = _.cloneDeep(this.modelValue);
      for (const tempItem of tempItems) {
        if(this.singleSelection){
          tempItem.selected = false;
        }
        if (tempItem.value === item.value) {
          tempItem.selected = item.selected;
        }
      }
      this.$emit("update:modelValue", tempItems);
      this.$emit("change");
    },
  },
});
