
import { defineComponent } from "vue";
import MdCheckboxMixin from "./MdCheckboxMixin";
import { uuid } from "@/utils/MdUuid";

export default defineComponent({
  name: "MdCheckbox",
  mixins: [MdCheckboxMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("checkbox"),
    },
  },
});
