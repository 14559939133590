import { getSecure } from "@/api/ApiUtil";
import { IEmployee } from "@/types/Employee";
import { IEmployeeHours } from "@/types/adminCenter/AdminCenterEmplyoeeHours";

export const findAll = async (): Promise<IEmployee[]> => {
  return (await getSecure(`/employee`)) as IEmployee[];
};

export const findEmployeeById = async (
  employeeId: string
): Promise<IEmployee> => {
  return (await getSecure(`/employee/${employeeId}`)) as IEmployee;
};

export const getEmployeeHours = async (
  employeeId: string
): Promise<IEmployeeHours> => {
  return (await getSecure(`/employee/hours/${employeeId}`)) as IEmployeeHours;
};
