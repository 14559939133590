
import { defineComponent } from "vue";
import { ContentLoader } from "vue-content-loader";

export default defineComponent({
  name: "MdTableCellLoader",
  components: {
    ContentLoader,
  },
});
