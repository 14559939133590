import { getSecure, postSecure, putSecure } from "@/api/ApiUtil";
import { ITeam } from "@/types/Team";
import { IAdminCenterTeamCreateHelper } from "@/types/adminCenter/AdminCenterTeamCreateHelper";
import { IAdminCenterTeamMembers } from "@/types/adminCenter/AdminCenterTeamMembers";
import { ITargetValue } from "@/types/targetValue/TargetValue";
import { IAdminCenterSimpleEmployeeData } from "@/types/adminCenter/AdminCenterSimpleEmployeeData";

export const findAll = async (): Promise<ITeam[]> => {
  return (await getSecure("/team")) as ITeam[];
};

export const findById = async (id: string): Promise<ITeam> => {
  return (await getSecure(`/team/${id}`)) as ITeam;
};

export const create = async (team: IAdminCenterTeamCreateHelper) => {
  // create new record
  return (await postSecure(`/team`, JSON.stringify(team))) as boolean;
};
export const deleteTeam = async (teamIds: string[]): Promise<boolean> => {
  return (await postSecure(
    `/team/deleteTeam`,
    JSON.stringify({
      teamIds: teamIds,
    })
  )) as boolean;
};

export const update = async (
  teamId: string,
  team: IAdminCenterTeamCreateHelper
): Promise<IAdminCenterTeamCreateHelper> => {
  // update existing record
  return (await putSecure(
    `/team/${teamId}`,
    JSON.stringify(team)
  )) as IAdminCenterTeamCreateHelper;
};

export const findAllTeamMembersByTeamId = async (teamId: string) => {
  return (await getSecure(
    `/team/getMembersByTeamId/${teamId}`
  )) as IAdminCenterTeamMembers;
};

export const findAllAvailableMembersByTeamLeader = async (
  teamLeaderId: string,
  showActive?: boolean
) => {
  return (await getSecure(
    `/team/getAvailableMembersByTeamLeader/${teamLeaderId}?showActive=${
      showActive || false
    }`
  )) as IAdminCenterSimpleEmployeeData[];
};
export const findAllAvailableSubstitutesByTeamLeader = async (
  teamLeaderId: string,
  showActive?: boolean
): Promise<IAdminCenterSimpleEmployeeData[]> => {
  return (await getSecure(
    `/team/getAvailableSubstitutesByTeamLeader/${teamLeaderId}?showActive=${
      showActive || false
    }`
  )) as IAdminCenterSimpleEmployeeData[];
};
export const updateAllTeamMembers = async (
  teamId: string,
  members: IAdminCenterTeamMembers
): Promise<IAdminCenterTeamMembers> => {
  return (await postSecure(
    `/team/updateTeamMembers/${teamId}`,
    JSON.stringify(members)
  )) as IAdminCenterTeamMembers;
};
export const updateTeamTargetValues = async (
  teamId: string,
  data: { targetValues: ITargetValue[],
  targetTurnoverByMembers: boolean },
) => {
  return (await postSecure(
    `/team/updateTeamTargetValues/${teamId}`,
    JSON.stringify(data)
  )) as boolean;
};
