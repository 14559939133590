import { App } from "vue";

// Components
import {
  MdNavigationItem,
  MdNavigationDivider,
  MdNavigationHeadline,
} from "./components/md/MdSidebar";

const registerGlobalComponents = (app: App): void => {
  app.component("md-navigation-item", MdNavigationItem);
  app.component("md-navigation-divider", MdNavigationDivider);
  app.component("md-navigation-headline", MdNavigationHeadline);
};

export { registerGlobalComponents };
