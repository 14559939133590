import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d873624"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_checkbox = _resolveComponent("md-checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: "md-menu-wrapper",
    style: _normalizeStyle({
      width: _ctx.width + 'px',
      transform: _ctx.isReversed
        ? 'translateY(-' + (_ctx.itemHeight * _ctx.items.length + 20 + 42) + 'px)'
        : '',
    }),
    id: "wrap"
  }, [
    _createElementVNode("ul", {
      class: _normalizeClass(["md-menu", [_ctx.small ? 'small' : '', _ctx.variant]])
    }, [
      _createVNode(_TransitionGroup, {
        name: "menu-list",
        onBeforeEnter: _ctx.onBeforeEnter,
        onEnter: _ctx.onEnter,
        onLeave: _ctx.onLeave
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.isReversed ? _ctx.items.slice().reverse() : _ctx.items, (items, key) => {
            return (_openBlock(), _createElementBlock("li", {
              key: key,
              class: _normalizeClass({ icon: items.icon || _ctx.iconSpace }),
              onClick: ($event: any) => (_ctx.onSelect(key as number))
            }, [
              (items.icon)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(items.icon), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(items.label), 1),
              (_ctx.isMultiSelect)
                ? (_openBlock(), _createBlock(_component_md_checkbox, {
                    key: 1,
                    class: "trailing-checkbox",
                    "model-value": items.selected,
                    disabled: true
                  }, null, 8, ["model-value"]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_1))
          }), 128))
        ]),
        _: 1
      }, 8, ["onBeforeEnter", "onEnter", "onLeave"])
    ], 2)
  ], 4))
}