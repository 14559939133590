import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    name: String,
    disabled: Boolean,
    required: Boolean,
    value: [String, Number, Boolean, Object, Array],
    modelValue: [String, Number, Boolean, Object, Array],
    align: {
      type: String as PropType<"left" | "right">,
      default: () => "left",
    },
    checked: Boolean,
  },
  emits: ["update:modelValue"],
  mounted() {
    if (this.checked === true) this.toggleCheck();
  },
  computed: {
    isChecked(): boolean {
      if (Array.isArray(this.modelValue))
        return this.modelValue.includes(this.value);
      if (this.value) return this.modelValue === this.value;
      return this.modelValue === true;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  methods: {
    handleArrayCheckbox() {
      const model = this.modelValue as Array<
        string | number | boolean | Record<string, unknown> | unknown
      >;
      if (!this.isChecked) {
        model.push(this.value);
      } else {
        const index = model.indexOf(this.value);
        if (index !== -1) model.splice(index, 1);
      }
      this.$emit("update:modelValue", model);
    },
    toggleOnClick() {
      if (this.disabled) return;
      this.toggleCheck();
    },
    toggleCheck() {
      if (Array.isArray(this.modelValue)) {
        this.handleArrayCheckbox();
      } else if (this.value) {
        this.$emit("update:modelValue", this.isChecked ? null : this.value);
      } else {
        this.$emit("update:modelValue", !this.isChecked);
      }
    },
  },
});
