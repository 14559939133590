
import { Bar } from "vue-chartjs";
import { useStore } from "@/store/FilterStore";
import { defineComponent, ref, watch } from "vue";
import { defaultValues, IChartDataWrapper } from "@/types/ChartData";
import { useStore as useChartColorStore } from "@/store/ChartColorStore";
import _ from "lodash";
import { defaultBarChartConfig } from "@/utils/GlobalChartConfig";
import { useStore as useColorStore } from "@/store/ColorStore";
import { IDegreeOfCompletionHourDistribution } from "@/types/degreeOfCompletion/DegreeOfCompletionHourDistribution";
import { findAllAnnualFinanceOpenHourDistribution } from "@/api/DegreeOfCompletionApi";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartType,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { numberToHourValue } from "@/utils/NumberFormatUtil";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default defineComponent({
  name: "AnnualFinancialOpenHourDistribution",
  props: {
    year: { type: String, default: "" },
    chartHeight: { type: Number, default: 140 },
    orderTypes: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  components: { Bar },
  setup(props) {
    const store = useStore();
    watch(
      () => store.loaded,
      () => {
        if (store.loaded) {
          fetchData();
        }
      }
    );
    watch(
      () => props.year,
      () => {
        fetchData();
      }
    );
    watch(
      () => props.orderTypes,
      () => {
        fetchData();
      }
    );
    const chartDataWrapper = ref({} as IChartDataWrapper);
    const loaded = ref(false as boolean);
    const response = ref({} as IDegreeOfCompletionHourDistribution);
    const barColors = ref([
      useChartColorStore().chartColors[0],
      useChartColorStore().chartColors[10],
    ] as string[]);

    chartDataWrapper.value.chartOptions = _.cloneDeep(defaultBarChartConfig());

    chartDataWrapper.value.chartOptions.indexAxis = "y";
    if (
      chartDataWrapper.value.chartOptions.plugins &&
      chartDataWrapper.value.chartOptions.plugins.legend &&
      chartDataWrapper.value.chartOptions.scales &&
      chartDataWrapper.value.chartOptions.plugins.tooltip?.callbacks
    ) {
      chartDataWrapper.value.chartOptions.plugins.legend.display = false;
      chartDataWrapper.value.chartOptions.scales.y = {
        grid: { display: false, drawBorder: false },
        ticks: {
          font: {
            family: "Roboto",
            style: "normal",
            size: 14,
            weight: "400",
          },
          color: useColorStore().chartTextColor,
        },
      };
      chartDataWrapper.value.chartOptions.plugins.tooltip.callbacks.title = (
        cData: TooltipItem<ChartType>[]
      ) => {
        if (cData[0].dataset.label) {
          return `${cData[0].dataset.label[cData[0].dataIndex]}`;
        } else {
          return "";
        }
      };
      chartDataWrapper.value.chartOptions.plugins.tooltip.callbacks.label = (
        cData: TooltipItem<ChartType>
      ) => {
        return `${numberToHourValue(cData.raw as number)}`;
      };
      chartDataWrapper.value.chartOptions.scales.x = {
        grid: { display: true, drawBorder: false, lineWidth: 2 },
        ticks: {
          color: useColorStore().chartScaleColor,
          callback: (value: string | number) =>
            Intl.NumberFormat("de", { notation: "standard" }).format(
              parseInt(value as string)
            ),
        },
      };
    }

    const fetchData = async () => {
      try {
        response.value = await findAllAnnualFinanceOpenHourDistribution(
          props.year,
          props.orderTypes as string[]
        );
        chartDataWrapper.value.chartData = {
          labels: ["Planstunden", "Erfasste Stunden"],
          datasets: [
            {
              minBarLength: defaultValues.minBarLength,
              barThickness: 24,
              label: ["Planstunden", "Erfasste Stunden"] as string[],
              backgroundColor: barColors.value,
              data: [response.value.plannedHours, response.value.timeUnits],
            },
          ],
        };
        loaded.value = true;
      } catch (e) {
        console.error(e);
      }
    };
    return { chartDataWrapper, response, fetchData, loaded };
  },
  mounted() {
    const filterStore = useStore();
    if (filterStore.loaded) {
      this.fetchData();
    }
    filterStore.$onAction((context) => {
      if (context.name === "updateFilter") {
        this.fetchData();
      }
    }, false);
  },
});
