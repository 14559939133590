
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "MdTooltip",
  props: {
    text: String,
    position: {
      type: String as PropType<
        "bottom" | "top" | "left" | "right" | "bottom-left" | "top-left"
      >,
      default: "bottom",
    },
  },
  data() {
    return {
      show: false,
    };
  },
});
