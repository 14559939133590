
import { defineComponent, ref } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdCard from "@/components/md/MdCard/MdCard.vue";
import { useStore } from "@/store/UserStore";
import LegalLinks from "@/components/LegalLinks.vue";

export default defineComponent({
  name: "TopNavBarButton",
  components: { LegalLinks, MdCard, MdButton },
  setup() {
    const overlayVisible = ref(false as boolean);
    const userStore = useStore();
    const logout = () => {
      userStore.logout();
    };
    return {
      overlayVisible,
      userStore,
      logout,
    };
  },
});
