import { useStore } from "@/store/FilterStore";
import { postSecure, toRequestParam } from "@/api/ApiUtil";
import { IAnnualFinancialDegreeOfCompletionTable } from "@/types/degreeOfCompletion/DegreeOfCompletionTable";
import {
  IAnnualFinancialEmployeeTable,
  IPrivateTaxesEmployeeTable,
} from "@/types/degreeOfCompletion/EmployeeDegreeOfCompletionTable";
import { IPaginationParameters } from "@/types/PaginationParameters";
import { IDegreeOfCompletionPrivateTaxesDegreeOfCompletionKpi } from "@/types/degreeOfCompletion/DegreeOfCompletionPrivateTaxesDegreeOfCompletionKpi";
import { IDegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi } from "@/types/degreeOfCompletion/DegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi";
import { IPaginationInterface } from "@/types/PaginationInterface";
import { IDegreeOfCompletionHourDistribution } from "@/types/degreeOfCompletion/DegreeOfCompletionHourDistribution";
import { IPrivateTaxesTable } from "@/types/degreeOfCompletion/PrivateTaxesTable";
import { IDegreeOfCompletionDetailedTable } from "@/types/degreeOfCompletion/DegreeOfCompletionDetailedTable";

export const findAllAnnualFinancialDegreeOfCompletionTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IAnnualFinancialDegreeOfCompletionTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/annualFinancialDegreeOfCompletionTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IAnnualFinancialDegreeOfCompletionTable>;
};
export const findAllPrivateTaxesDegreeOfCompletionTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IPrivateTaxesTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/privateTaxesDegreeOfCompletionTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IPrivateTaxesTable>;
};

export const findAllAnnualFinancialEmployeeTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IAnnualFinancialEmployeeTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/annualFinancialDegreeOfCompletionEmployeeTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IAnnualFinancialEmployeeTable>;
};
export const findAllPrivateTaxesEmployeeTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IPrivateTaxesEmployeeTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/privateTaxesDegreeOfCompletionEmployeeTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IPrivateTaxesEmployeeTable>;
};
export const findAllDegreeOfCompletionAnnualFinancialInvoicedTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/annualFinancialInvoicedTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
};

export const findAllDegreeOfCompletionPrivateTaxesInvoicedTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/privateTaxesInvoicedTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
};
export const findAllDegreeOfCompletionAnnualFinancialOpenTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/annualFinancialOpenTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
};

export const findAllDegreeOfCompletionPrivateTaxesOpenTable = async (
  parameter: IPaginationParameters,
  year: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/privateTaxesOpenTable/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
};
export const findAllDegreeOfCompletionAnnualFinancialOpenEmployeeTable = async (
  parameter: IPaginationParameters,
  year: string,
  employeeId: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/annualFinancialOpenEmployeeTable/assessmentYear/${year}/employee/${employeeId}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
};
export const findAllDegreeOfCompletionPrivateTaxesOpenEmployeeTable = async (
  parameter: IPaginationParameters,
  year: string,
  employeeId: string,
  orderTypes: Array<string>
): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
  const filterStore = useStore();
  const filter = { ...filterStore.filter, ...parameter };
  const param = toRequestParam({ orderType: orderTypes });
  return (await postSecure(
    `/degreeOfCompletion/privateTaxesOpenEmployeeTable/assessmentYear/${year}/employee/${employeeId}?${param}`,
    JSON.stringify(filter)
  )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
};
export const findAllDegreeOfCompletionAnnualFinancialInvoicedEmployeeTable =
  async (
    parameter: IPaginationParameters,
    year: string,
    employeeId: string,
    orderTypes: Array<string>
  ): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
    const filterStore = useStore();
    const filter = { ...filterStore.filter, ...parameter };
    const param = toRequestParam({ orderType: orderTypes });
    return (await postSecure(
      `/degreeOfCompletion/annualFinancialInvoicedEmployeeTable/assessmentYear/${year}/employee/${employeeId}?${param}`,
      JSON.stringify(filter)
    )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
  };
export const findAllDegreeOfCompletionPrivateTaxesInvoicedEmployeeTable =
  async (
    parameter: IPaginationParameters,
    year: string,
    employeeId: string,
    orderTypes: Array<string>
  ): Promise<IPaginationInterface<IDegreeOfCompletionDetailedTable>> => {
    const filterStore = useStore();
    const filter = { ...filterStore.filter, ...parameter };
    const param = toRequestParam({ orderType: orderTypes });
    return (await postSecure(
      `/degreeOfCompletion/privateTaxesInvoicedEmployeeTable/assessmentYear/${year}/employee/${employeeId}?${param}`,
      JSON.stringify(filter)
    )) as IPaginationInterface<IDegreeOfCompletionDetailedTable>;
  };
export const findAllPrivateTaxesDegreeOfCompletionKpi = async (
  year: string,
  orderTypes: Array<string>
): Promise<IDegreeOfCompletionPrivateTaxesDegreeOfCompletionKpi> => {
  const filterStore = useStore();
  const filter = filterStore.filter;
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/privateTaxesDegreeOfCompletionKpi/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IDegreeOfCompletionPrivateTaxesDegreeOfCompletionKpi;
};

export const findAllAnnualFinanceDegreeOfCompletionKpi = async (
  year: string,
  orderTypes: Array<string>
): Promise<IDegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi> => {
  const filterStore = useStore();
  const filter = filterStore.filter;
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/annualFinancialDegreeOfCompletionKpi/assessmentYear/${year}?${param}`,
    JSON.stringify(filter)
  )) as IDegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi;
};

export const findAllAnnualFinanceInvoicedHourDistribution = async (
  year: string,
  orderTypes: Array<string>
): Promise<IDegreeOfCompletionHourDistribution> => {
  const filterStore = useStore();
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/annualFinancialInvoicedHoursDistribution/assessmentYear/${year}?${param}`,
    JSON.stringify(filterStore.filter)
  )) as IDegreeOfCompletionHourDistribution;
};
export const findAllAnnualFinanceOpenHourDistribution = async (
  year: string,
  orderTypes: Array<string>
): Promise<IDegreeOfCompletionHourDistribution> => {
  const filterStore = useStore();
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/annualFinancialOpenHoursDistribution/assessmentYear/${year}?${param}`,
    JSON.stringify(filterStore.filter)
  )) as IDegreeOfCompletionHourDistribution;
};

export const findAllPrivateTaxesInvoicedHourDistribution = async (
  year: string,
  orderTypes: Array<string>
): Promise<IDegreeOfCompletionHourDistribution> => {
  const filterStore = useStore();
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/privateTaxesInvoicedHoursDistribution/assessmentYear/${year}?${param}`,
    JSON.stringify(filterStore.filter)
  )) as IDegreeOfCompletionHourDistribution;
};
export const findAllPrivateTaxesOpenHourDistribution = async (
  year: string,
  orderTypes: Array<string>
): Promise<IDegreeOfCompletionHourDistribution> => {
  const filterStore = useStore();
  const param = toRequestParam({ orderType: orderTypes });

  return (await postSecure(
    `/degreeOfCompletion/privateTaxesOpenHoursDistribution/assessmentYear/${year}?${param}`,
    JSON.stringify(filterStore.filter)
  )) as IDegreeOfCompletionHourDistribution;
};
