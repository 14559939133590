
import { defineComponent, PropType } from "vue";
import { IMenuItem } from "@/components/md/MdMenu";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";
import _ from "lodash";

export default defineComponent({
  name: "MdMultiSelectItem",
  components: { MdCheckbox },
  props: {
    value: {
      type: Object as PropType<IMenuItem>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    update() {
      const tempItem: IMenuItem = _.cloneDeep(this.value);
      tempItem.selected = !this.value.selected;
      this.$emit("itemChange", tempItem);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue: IMenuItem) {
        if (newValue.selected !== this.isSelected) {
          this.isSelected = !!newValue.selected;
        }
      },
    },
  },
});
