import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e648c74"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["cx", "cy", "r"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_loader = _resolveComponent("content-loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md-avatar-loader", [_ctx.size]])
  }, [
    _createVNode(_component_content_loader, {
      viewBox: '0 0 ' + _ctx.width + ' ' + _ctx.width,
      speed: 1.2,
      primaryColor: "#dad9e3",
      secondaryColor: "#cecdd8"
    }, {
      default: _withCtx(() => [
        _createElementVNode("circle", {
          cx: _ctx.radius,
          cy: _ctx.radius,
          r: _ctx.radius
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["viewBox", "speed"])
  ], 2))
}