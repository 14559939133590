
import { defineComponent, ref, watch } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdTooltip from "@/components/md/MdTooltip/MdTooltip.vue";
import MdSelect from "@/components/md/MdInput/MdSelect.vue";
import { IMenuItem } from "@/components/md/MdMenu";

export default defineComponent({
  name: "MdTableFooter",
  props: {
    total: [Number, String],
    hasSum: Boolean,
    page: Number,
    rowsPerPage: Number,
    nextPage: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MdSelect,
    MdTooltip,
    MdButton,
  },
  setup(props, context) {
    const size = ref(props.rowsPerPage as number);
    const sizes = ref([] as IMenuItem[]);
    watch(
      () => props.rowsPerPage,
      () => {
        if (props.rowsPerPage) {
          size.value = props.rowsPerPage;
        }
      }
    );

    sizes.value = [
      { label: "5", value: 5 as number },
      { label: "10", value: 10 as number },
      { label: "15", value: 15 as number },
      { label: "alle", value: -1 as number },
    ];
    const updatePageSize = () => {
      if (size.value === -1) {
        // if table has a sum the table has one more row than totalElements
        if (props.hasSum) {
          context.emit("updatePageSize", (props.total as number) + 1);
        } else {
          context.emit("updatePageSize", props.total);
        }
      } else {
        context.emit("updatePageSize", size.value);
      }
    };
    return { size, sizes, updatePageSize };
  },
  emits: ["prev", "next", "firstPage", "lastPage", "updatePageSize"],
});
