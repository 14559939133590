
import { defineComponent, PropType } from "vue";
import Clickable from "@/components/mixins/Clickable";

export default defineComponent({
  name: "MdCard",
  mixins: [Clickable],
  props: {
    clickable: Boolean,
    variant: {
      type: String as PropType<"filled" | "elevated" | "outlined">,
      required: false,
      default: () => {
        return "filled";
      },
    },
  },
});
