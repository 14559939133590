
import { defineComponent, PropType } from "vue";
import { RowBadgeTypes } from "../MdTable";

export default defineComponent({
  name: "MdBadge",
  props: {
    state: {
      type: String as PropType<RowBadgeTypes>,
      default: "neutral",
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconsByState: {
        success: "check_circle",
        error: "error",
        warning: "warning",
        neutral: "info",
        info: "info",
      },
    };
  },
});
