
import { useStore } from "@/store/FilterStore";
import dayjs from "dayjs";
import { computed, defineComponent, ref } from "vue";
import MdDateRangePicker from "@/components/md/MdPicker/MdDateRangePicker.vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MdInputChip from "@/components/md/MdChips/MdInputChip.vue";

export default defineComponent({
  name: "DateRangeFilterBtn",
  components: { MdButton, MdInputChip, MdDateRangePicker },
  emits: ["onchange"],
  props: {
    leadingIcon: {
      type: String,
      required: true,
    },
    trailingIcon: {
      type: String,
      default: "cancel",
    },
    overlayHeading: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const id = "DateFilterBtn";
    const filterStore = useStore();
    const errorMessage = ref("" as string);
    const fromDate = ref("" as string);
    const toDate = ref("" as string);
    const fromInput = ref("" as string);
    const toInput = ref("" as string);
    const errorActive = ref(false as boolean);
    const isVisible = ref(false as boolean);
    const pickerValue = ref({ startDate: new Date(), endDate: new Date() });

    const initDates = () => {
      fromDate.value = filterStore.fromDate;
      toDate.value = filterStore.$state.filter.to;
    };
    filterStore.$subscribe((mutation, state) => {
      isVisible.value = state.openFilterOverlayPanel == id;
      initDates();
    });

    const setDate = () => {
      fromInput.value = dayjs(pickerValue.value.startDate)
        .format("DD.MM.YYYY")
        .toString();
      toInput.value = dayjs(pickerValue.value.endDate)
        .format("DD.MM.YYYY")
        .toString();
    };

    const toggleOverlayVisibility = () => {
      if (filterStore.$state.openFilterOverlayPanel == null) {
        filterStore.$state.openFilterOverlayPanel = id;
      } else if (filterStore.$state.openFilterOverlayPanel == id) {
        // this one open
        filterStore.$state.openFilterOverlayPanel = null;
      } else {
        // other open
        filterStore.$state.openFilterOverlayPanel = id;
      }
    };

    const submit = () => {
      fromDate.value = dayjs(fromInput.value, "DD.MM.YYYY")
        .format("YYYY-MM-DD")
        .toString();
      toDate.value = dayjs(toInput.value, "DD.MM.YYYY")
        .format("YYYY-MM-DD")
        .toString();
      toggleOverlayVisibility();
      context.emit("onchange", fromDate.value, toDate.value);
    };

    const dateLabel = computed(
      () =>
        `${dayjs(fromDate.value, "YYYY-MM-DD").format("DD.MM.YYYY")} - ${dayjs(
          toDate.value,
          "YYYY-MM-DD"
        ).format("DD.MM.YYYY")}`
    );

    const formatFromInput = (event: InputEvent) => {
      if (
        fromInput.value.length === 3 &&
        event.inputType === "insertText" &&
        event.data !== "."
      ) {
        fromInput.value =
          fromInput.value.slice(0, 2) + "." + fromInput.value.at(2);
      } else if (
        fromInput.value.length === 6 &&
        event.inputType === "insertText" &&
        event.data !== "."
      ) {
        fromInput.value =
          fromInput.value.slice(0, 5) + "." + fromInput.value.at(5);
      }
    };
    const formatToInput = (event: InputEvent) => {
      if (
        toInput.value.length === 3 &&
        event.inputType === "insertText" &&
        event.data !== "."
      ) {
        toInput.value = toInput.value.slice(0, 2) + "." + toInput.value.at(2);
      } else if (
        toInput.value.length === 6 &&
        event.inputType === "insertText" &&
        event.data !== "."
      ) {
        toInput.value = toInput.value.slice(0, 5) + "." + toInput.value.at(5);
      }
    };
    const inputChanged = () => {
      if (
        dayjs(fromInput.value, "DD.MM.YYYY").format() === "Invalid Date" ||
        dayjs(toInput.value, "DD.MM.YYYY").format() === "Invalid Date"
      ) {
        errorActive.value = true;

        errorMessage.value =
          "Inkorrektes Datumformat. Bitte TT.MM.JJJJ verwenden.";
      } else {
        errorActive.value = false;
        errorMessage.value = "";
        pickerValue.value = {
          startDate: new Date(dayjs(fromInput.value, "DD.MM.YYYY").format()),
          endDate: new Date(dayjs(toInput.value, "DD.MM.YYYY").format()),
        };
      }
    };

    return {
      pickerValue,
      fromDate,
      toDate,
      isVisible,
      initDates,
      toggleOverlayVisibility,
      submit,
      dateLabel,
      setDate,
      inputChanged,
      fromInput,
      toInput,
      errorMessage,
      errorActive,
      formatFromInput,
      formatToInput,
    };
  },
  mounted() {
    this.initDates();
    dayjs.extend(customParseFormat);
    this.fromInput = dayjs(this.fromDate).format("DD.MM.YYYY");
    this.toInput = dayjs(this.toDate).format("DD.MM.YYYY");
  },
});
