
import { defineComponent, PropType } from "vue";
import MdMenuMixin from "./MdMenuMixin";
import gsap from "gsap";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";

/**
 * TODOS
 * - add navigation with keys
 * - enter to submit
 * - keyboard arrows for up and down
 * - pre select (like hovered state) the first value
 * - add sub menus
 */

export default defineComponent({
  name: "MdMenu",
  mixins: [MdMenuMixin],
  components: { MdCheckbox },
  emits: ["select"],
  props: {
    isMultiSelect: { type: Boolean, default: false },
    selected: { type: Boolean, default: true },
    iconSpace: Boolean,
    small: Boolean,
    dynamicPosition: Boolean,
    width: Number,
    variant: {
      type: String as PropType<"default" | "flat" | "outlined">,
      default: "default",
    },
  },
  computed: {
    itemHeight(): number {
      return this.small ? 32 : 48;
    },
    itemVerticalPadding(): number {
      return this.small ? 6 : 14;
    },
    index(): number {
      if (this.value) {
        return this.items.findIndex((item) => {
          return item.value === this.value;
        });
      }
      return 0;
    },
    offsetTop(): number {
      if (!this.dynamicPosition) return 0;
      return this.getOffsetTopByIndex(this.index);
    },
  },
  methods: {
    onSelect(index: number) {
      const item = this.items[index];
      if (!item) return;
      // item.selected = !item.selected;
      this.$emit("select", item.value, item);
    },
    getOffsetTopByIndex(index: number): number {
      const ulPaddingTop = 8;
      return ulPaddingTop + this.itemHeight * (index + 1);
    },
    onBeforeEnter(el: HTMLElement) {
      el.style.opacity = "0";
      el.style.height = "0";
      el.style.paddingTop = "0";
      el.style.paddingBottom = "0";
    },
    onEnter(el: Element, done: gsap.Callback) {
      gsap.to(el, {
        opacity: 1,
        height: this.itemHeight + "px",
        paddingTop: this.itemVerticalPadding + "px",
        paddingBottom: this.itemVerticalPadding + "px",
        delay: 0.125,
        onComplete: done,
      });
    },
    onLeave(el: Element, done: gsap.Callback) {
      gsap.to(el, {
        opacity: 0,
        height: "0",
        paddingTop: "0",
        paddingBottom: "0",
        delay: 0.125,
        onComplete: done,
      });
    },
  },
});
