import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!

  return (_openBlock(), _createBlock(_component_md_table, {
    clickable: true,
    data: _ctx.rows,
    sum: _ctx.sum,
    pagination: true,
    "search-header": true,
    sortable: true,
    "table-options": _ctx.tableOptions,
    loading: _ctx.loading,
    "download-link": 
      '/degreeOfCompletion/annualFinancialDegreeOfCompletionEmployeeTable/assessmentYear/' +
      _ctx.year +
      '/excel?' +
      _ctx.toRequestParam({ orderType: _ctx.orderTypes })
    ,
    onFetchData: _ctx.fetchData,
    onClick: _ctx.extendOverlay
  }, {
    default: _withCtx(() => [
      _createVNode(_component_md_table_column, {
        title: "Personalnr",
        display: "orderEmployeeDisplayNumber"
      }),
      _createVNode(_component_md_table_column, {
        title: "Mitarbeiter",
        display: "orderEmployeeDisplayName"
      }),
      _createVNode(_component_md_table_column, {
        numeric: true,
        title: "Anzahl Aufträge (Gesamt)",
        display: "orders"
      }),
      _createVNode(_component_md_table_column, {
        numeric: true,
        title: "Fertigstellungsgrad nach Aufträgen",
        display: "degreeOfCompletionOrders",
        format: "percentage"
      }),
      _createVNode(_component_md_table_column, {
        numeric: true,
        title: "Fertigstellungsgrad nach Planstunden",
        display: "degreeOfCompletionPlannedHours",
        format: "percentage"
      }),
      _createVNode(_component_md_table_column, {
        numeric: true,
        title: "Planstunden nicht fakturierte Aufträge",
        display: "openPlannedHours",
        format: "hour"
      }),
      _createVNode(_component_md_table_column, {
        numeric: true,
        title: "Ist-Stunden nicht fakturierte Aufträge",
        display: "openTimeUnits",
        format: "hour"
      })
    ]),
    _: 1
  }, 8, ["data", "sum", "table-options", "loading", "download-link", "onFetchData", "onClick"]))
}