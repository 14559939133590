import { useStore } from "@/store/UserStore";
import { NavigationGuardNext, RouteLocation } from "vue-router";
import router from "@/router/index";
export default async function (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext
): Promise<void> {
  const userStore = useStore();
  const currentRouteQuery = router.currentRoute.value.query;
  // Initial check if the user is still signed in.
  const query = {
    redirectName: to.name ? to.name.toString() : null,
    ...to.query,
  };
  try {
    await userStore.getUser();
  } catch (e) {
    next({
      name: "SignIn",
      query: query,
    });
  }

  // localhost:8080/#/turnover-distribution?asds=asdsa

  if (!userStore.user) {
    next({
      name: "SignIn",
      query: query,
    });
  } else {
    if (router.currentRoute.value.query.redirectName !== undefined) {
      next({ name: router.currentRoute.value.query.redirectName as string });
      delete currentRouteQuery.redirectName;
    } else {
      next();
    }
  }
}
