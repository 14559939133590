import { getSecure, postSecure, putSecure } from "@/api/ApiUtil";
import { IUser } from "@/types/User";
import { IUserSettings } from "@/types/userSettings/UserSettings";
import { IAdminCenterUser } from "@/types/adminCenter/AdminCenterUser";
import { IAdminCenterPasswordResetHelper } from "@/types/adminCenter/AdminCenterPasswordResetHelper";
import { IUserUpdate } from "@/types/userSettings/UserUpdate";

export const getUser = async (): Promise<IUser> => {
  return (await getSecure("/user")) as IUser;
};

export const getUserSettingInfo = async (): Promise<IUserSettings> => {
  return (await getSecure("/user/info")) as IUserSettings;
};

export const getUserUpdate = async (userId: string): Promise<IUserUpdate> => {
  return (await getSecure("/user/userUpdate/" + userId)) as IUserUpdate;
};

export const emailExists = async (email: string): Promise<boolean> => {
  return (await postSecure(
    "/user/emailExists",
    JSON.stringify({
      email: email,
    })
  )) as boolean;
};

export const createUser = async (user: IAdminCenterUser): Promise<boolean> => {
  return (await postSecure(
    `/user/createUser`,
    JSON.stringify(user)
  )) as boolean;
};

export const updateUser = async (user: IAdminCenterUser): Promise<boolean> => {
  return (await putSecure(`/user`, JSON.stringify(user))) as boolean;
};

export const deleteUsers = async (userIds: string[]): Promise<boolean> => {
  return (await postSecure(
    `/user/deleteUser`,
    JSON.stringify({
      userIds: userIds,
    })
  )) as boolean;
};
export const resetPassword = async (
  oldPassword: string,
  newPassword: string
): Promise<boolean> => {
  return (await postSecure(
    `/user/reset`,
    JSON.stringify({
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
  )) as boolean;
};
export const resetUserPassword = async (
  creds: IAdminCenterPasswordResetHelper
): Promise<boolean> => {
  return (await postSecure(
    `/user/resetUserPassword`,
    JSON.stringify(creds)
  )) as boolean;
};
