import { defineComponent } from "vue";

export default defineComponent({
  props: {
    indeterminate: {
      type: Boolean,
      default: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
});
