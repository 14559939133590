
import { defineComponent } from "vue";
import MdInputField from "@/components/md/MdInput/MdInputField.vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdTooltip from "@/components/md/MdTooltip/MdTooltip.vue";
import MdCard from "@/components/md/MdCard/MdCard.vue";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";
import { downloadSecure } from "@/api/ApiUtil";
import { useStore } from "@/store/FilterStore";

export default defineComponent({
  name: "MdTableSearchHeader",
  components: {
    MdCheckbox,
    MdCard,
    MdTooltip,
    MdButton,
    MdInputField,
  },
  emits: [
    "query",
    "reset",
    "updatePageSize",
    "toggleShowActive",
    "toggleShowNonUsers",
  ],
  data(props) {
    return {
      query: "",
      tableActiveOverlayVisible: false,
      nonUserEmployeeOverlayVisible: false,
      showActive: true,
      showNonUsers: props.showNonUserEmployees,
    };
  },
  props: {
    showActiveTableButton: {
      type: Boolean,
      required: true,
    },
    showNonUserEmployees: {
      type: Boolean,
      required: false,
    },
    showNonUserEmployeeFilter: {
      type: Boolean,
      required: false,
    },
    downloadLink: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  watch: {
    query() {
      this.$emit("query", this.query);
    },
  },
  methods: {
    toggleReset() {
      this.query = "";
      this.$emit("reset");
    },
    updatePageSize() {
      this.$emit("updatePageSize", 5);
    },
    tableActiveOverlayCancel() {
      this.tableActiveOverlayVisible = false;
    },
    nonUserEmployeesOverlayCancel() {
      this.nonUserEmployeeOverlayVisible = false;
    },
    tableActiveOverlaySubmit() {
      this.$emit("toggleShowActive", this.showActive);
      this.tableActiveOverlayVisible = false;
    },
    nonUserOverlaySubmit() {
      this.$emit("toggleShowNonUsers", this.showNonUsers, this.showActive);
      this.nonUserEmployeeOverlayVisible = false;
    },
    toggleTableActiveOverlayVisible(e: Event) {
      // don't close the dropdown on selection
      if (
        !(e.target as HTMLElement).classList.contains("filter-select-option")
      ) {
        this.tableActiveOverlayVisible = false;
      }
    },
    async download() {
      const filterStore = useStore();
      downloadSecure(this.downloadLink, filterStore.processedFilter);
    },
  },
});
