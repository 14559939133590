
import { computed, defineComponent, PropType, ref, watch } from "vue";
import MdInputChip from "@/components/md/MdChips/MdInputChip.vue";
import { useStore } from "@/store/FilterStore";
import MdMultiSelect from "@/components/md/MdInput/MdMultiSelect.vue";
import MdCard from "@/components/md/MdCard/MdCard.vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import { IMenuItem } from "@/components/md/MdMenu";
import _ from "lodash";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";

export default defineComponent({
  name: "MdFilterButton",
  components: {
    MdCheckbox,
    MdButton,
    MdCard,
    MdMultiSelect,
    MdInputChip,
  },
  props: {
    icon: {
      type: String,
      required: false,
    },
    btnHeader: { type: String, default: "ButtonHeader" },
    btnLabel: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    showFilterCheckbox: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    filterCheckboxLabel: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  emits: ["update:modelValue", "change", "filterCheckboxValueChanged"],
  setup(props, context) {
    const filterStore = useStore();
    const filterCheckboxValue = ref(true);
    const itemsRef = ref(_.cloneDeep(props.modelValue));
    const isVisible = ref(false as boolean);

    filterStore.$subscribe((mutation, state) => {
      isVisible.value = state.openFilterOverlayPanel == props.btnLabel;
    });
    const toggleOverlayVisibility = () => {
      if (filterStore.$state.openFilterOverlayPanel == null) {
        filterStore.$state.openFilterOverlayPanel = props.btnLabel;
        // Sort items by selected
        sortItems();
      } else if (filterStore.$state.openFilterOverlayPanel == props.btnLabel) {
        // this one open
        filterStore.$state.openFilterOverlayPanel = null;
      } else {
        // other open
        filterStore.$state.openFilterOverlayPanel = props.btnLabel;
        // Sort items by selected
        sortItems();
      }
      isVisible.value = !!filterStore.$state.openFilterOverlayPanel;
    };
    const sortItems = () => {
      itemsRef.value.sort((a, b) => {
        return a.selected === b.selected
          ? 0
          : a.selected
          ? -1
          : 1 || a.label.localeCompare(b.label);
      });
    };
    const cancel = () => {
      toggleOverlayVisibility();
    };
    const resetFilter = () => {
      itemsRef.value.forEach((e) => {
        if (e.selected) {
          e.selected = false;
        }
      });
      submit();
    };
    const submit = () => {
      context.emit("update:modelValue", itemsRef.value);
      context.emit("change", itemsRef.value);
      toggleOverlayVisibility();
    };
    watch(
      () => props.modelValue,
      () => {
        if (itemsRef.value !== props.modelValue) {
          itemsRef.value = props.modelValue;
        }
      },
      { deep: true, immediate: true }
    );

    const chipLabel = computed((): string => {
      const selectedItems = props.modelValue.filter((o) => o.selected);
      if (selectedItems.length === 0) {
        return props.btnLabel;
      } else if (selectedItems.length === 1) {
        return selectedItems[0].label;
      } else {
        return `${selectedItems[0].label} und ${
          selectedItems.length - 1
        } weitere`;
      }
    });

    const hasValue = computed((): boolean => {
      return props.modelValue.filter((o) => o.selected).length > 0;
    });

    const emitFilterCheckbox = () => {
      context.emit("filterCheckboxValueChanged", filterCheckboxValue.value);
    };

    return {
      itemsRef,
      isVisible,
      hasValue,
      filterCheckboxValue,
      toggleOverlayVisibility,
      resetFilter,
      cancel,
      chipLabel,
      submit,
      emitFilterCheckbox,
    };
  },
});
