
import { useStore } from "@/store/FilterStore";
import { storeToRefs } from "pinia";
import { defineComponent, ToRefs } from "vue";

export default defineComponent({
  name: "ErrorPanel",
  setup() {
    const filterStore = useStore();

    const state: ToRefs<{
      corporateStructures: { id: string; label: string }[];
      establishments: { id: string; label: string }[];
      employees: { id: string; label: string }[];
      filter: {
        corporateStructures: string[];
        establishments: string[];
        employees: string[];
        from: string;
        to: string;
        error: string | null;
      };
    }> = storeToRefs(filterStore);

    return {
      state,
    };
  },

  computed: {
    isVisible() {
      return this.state.filter.value.error != null;
    },
  },
});
