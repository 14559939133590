
import { defineComponent } from "vue";
import MdInputMixin from "./MdInputMixin";
import { uuid } from "@/utils/MdUuid";
import { IMenuItem, TransitionMdMenu } from "../MdMenu";
import MdMenuMixin from "../MdMenu/MdMenuMixin";
import MdSelectMenu from "@/components/md/MdMenu/MdSelectMenu.vue";

export default defineComponent({
  name: "MdSelect",
  components: {
    MdSelectMenu,
    TransitionMdMenu,
  },
  mixins: [MdInputMixin, MdMenuMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("select"),
    },
    titleValue: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:titleValue",
    "update:modelValue",
    "focus",
    "blur",
    "change",
    "search",
  ],
  data() {
    return {
      menuVisible: false,
      title: "",
      results: [] as IMenuItem[],
      query: "",
    };
  },
  mounted() {
    if (this.modelValue) {
      const item: IMenuItem | undefined = this.items.find((el: IMenuItem) => {
        return el.value === this.modelValue;
      });
      if (item) this.onChange(item.value, item);
    }
  },
  methods: {
    onFocus() {
      this.menuVisible = true;
      if (this.title.trim().length <= 0 && this.results.length <= 0)
        this.results = this.items;
    },
    onBlur() {
      setTimeout(() => {
        this.menuVisible = false;
      }, 250);
      if (!this.hasValue) {
        this.title = "";
        this.resetValues();
      }
    },
    onChange(value: number | string, item: IMenuItem) {
      //overrides default method from MdInputMixin
      (this.$refs["input"] as HTMLInputElement).value = item.label;
      this.search();
      this.hasValue = value.toString().length > 0;
      this.$emit("update:modelValue", value);
      this.$emit("change");
      this.title = item.label;
    },
    clearValues() {
      this.resetValues();
      (this.$refs["input"] as HTMLInputElement).value = "";
      this.hasValue = false;
      this.title = "";
      this.$emit("change");
    },
    resetValues() {
      this.$emit("update:modelValue");
      this.$emit("update:titleValue");
    },
    onKeyup() {
      const value = (this.$refs["input"] as HTMLInputElement).value.trim();
      this.$emit("search", value);
      this.query = value.toLowerCase().trim();
      setTimeout(() => this.search(), 100);
    },
    search() {
      if (!this.query || this.query.length <= 0) {
        this.results = this.items;
        return;
      }
      this.results = this.items.filter((item) =>
        item.label.toLowerCase().trim().includes(this.query)
      );
    },
  },
  watch: {
    immediate: {
      handler(val) {
        if (val) this.search();
      },
    },
    modelValue: {
      handler() {
        if (this.modelValue) {
          const item: IMenuItem | undefined = this.items.find(
            (el: IMenuItem) => {
              return el.value === this.modelValue;
            }
          );
          if (item) {
            //overrides default method from MdInputMixin
            (this.$refs["input"] as HTMLInputElement).value = item.label;
            this.search();
            this.hasValue = item.value.toString().length > 0;
            this.$emit("update:modelValue", item.value);
            this.title = item.label;
          }
        }
      },
    },
  },
});
