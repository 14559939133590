import { ITableOptions } from "@/types/TableOptions";
import { IPaginationInterface } from "@/types/PaginationInterface";
import { IPaginationParameters } from "@/types/PaginationParameters";

export const toTableOptions = (
  paginationInterface: IPaginationInterface<unknown>,
  paginationParameters: IPaginationParameters
): ITableOptions => {
  return {
    page: paginationInterface.number,
    size: paginationInterface.size,
    search: paginationParameters.search,
    sort: paginationParameters.sort,
    totalPages: paginationInterface.totalPages,
    totalElements: paginationInterface.totalElements,
  } as ITableOptions;
};
