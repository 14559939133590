
import { defineComponent, ref, watch } from "vue";
import { useStore } from "@/store/FilterStore";
import { findAllAnnualFinanceDegreeOfCompletionKpi } from "@/api/DegreeOfCompletionApi";
import { IDegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi } from "@/types/degreeOfCompletion/DegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi";
import { numberToPercentageValue } from "@/utils/NumberFormatUtil";
import KPITwoRows from "@/components/codin/KPITwoRows.vue";

export default defineComponent({
  name: "AnnualFinancialDegreeOfCompletionKpi",
  components: { KPITwoRows },
  props: {
    year: { type: String, default: "" },
    orderTypes: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  setup(props) {
    const hoursPercent = ref("" as string);
    const ordersPercent = ref("" as string);

    const loaded = ref(false as boolean);
    const response = ref(
      {} as IDegreeOfCompletionAnnualFinancialDegreeOfCompletionKpi
    );
    const store = useStore();
    watch(
      () => store.loaded,
      () => {
        if (store.loaded) {
          fetchData();
        }
      }
    );
    watch(
      () => props.year,
      () => {
        fetchData();
      }
    );
    watch(
      () => props.orderTypes,
      () => {
        fetchData();
      }
    );
    const fetchData = async () => {
      try {
        response.value = await findAllAnnualFinanceDegreeOfCompletionKpi(
          props.year,
          props.orderTypes as string[]
        );
        hoursPercent.value = numberToPercentageValue(
          response.value.hoursPercent
        );
        ordersPercent.value = numberToPercentageValue(
          response.value.ordersPercent
        );
        loaded.value = true;
      } catch (e) {
        console.error(e);
      }
    };
    return { fetchData, hoursPercent, ordersPercent };
  },
  mounted() {
    const filterStore = useStore();
    if (filterStore.loaded) {
      this.fetchData();
    }
    filterStore.$onAction((context) => {
      if (context.name === "updateFilter") {
        this.fetchData();
      }
    }, false);
  },
});
